import { createSlice } from '@reduxjs/toolkit'
import Handsontable from 'handsontable'

import { ProcessingStatus } from '~/features/deviceMapping/left-sidebar/OcrProcessingStatus'
import type { EstimateFormat, FileContent, ISelectedColumns, ISelectedConstruction, ISelectedMachineElement } from '~/features/deviceMapping/types'
import { LinkingTab, Step } from '~/features/deviceMapping/types'

export type Column = {
  label: string
  value: string
  required?: boolean
  isOption?: boolean
  isDefault?: boolean
}

export type UploadedFile = {
  file_id: string
  original_file_name: string
  file_name: string
  user_id: string
  status: ProcessingStatus
  alias?: string
}

interface DeviceMachineState {
  dataFile?: FileContent
  currentFile?: { id?: string; name?: string }
  isDeviceMappingFlow: boolean
  isPreview: boolean
  tableInstance?: Handsontable | null
  openRightSidebar: boolean
  activeStep: Step
  activeTab: LinkingTab
  selectedColumns: ISelectedColumns
  selectedConstructions: ISelectedConstruction[]
  selectedMachineElement: ISelectedMachineElement
  processingFile: any
  columns: Column[]
  showMapping?: boolean
  showReflectedModalType?: 'new' | 'override'
  /** the draft format is alway the last items in array */
  savedFormats: EstimateFormat[]
  usingFormatId?: string
  uploadedFiles: UploadedFile[]
  showNotificationOfFile?: {
    id: string
    type: 'info' | 'error'
  }
}

const initialState: DeviceMachineState = {
  currentFile: undefined,
  dataFile: {},
  isDeviceMappingFlow: false,
  isPreview: false,
  tableInstance: null,
  openRightSidebar: false,
  activeStep: Step.SELECT_COLUMN,
  activeTab: LinkingTab.ELEMENT_COOPERATION_TAB,
  selectedColumns: new Map(),
  selectedConstructions: [],
  selectedMachineElement: {},
  processingFile: {},
  columns: [],
  showMapping: false,
  showReflectedModalType: undefined,
  savedFormats: [],
  usingFormatId: undefined,
  uploadedFiles: [],
  showNotificationOfFile: undefined,
}

export const deviceMappingSlice = createSlice({
  name: 'deviceMapping',
  initialState,
  reducers: {
    setDataFile: (state, action: { type: string; payload: FileContent }) => {
      state.dataFile = action.payload
    },
    setCurrentFile: (state, action: { type: string; payload: { id?: string; name?: string } }) => {
      state.currentFile = action.payload
    },
    setIsDeviceMappingFlow: (state, action) => {
      state.isDeviceMappingFlow = action.payload
    },
    setIsPreview: (state, action) => {
      state.isPreview = action.payload
    },
    setTableInstance: (state, action) => {
      state.tableInstance = action.payload
    },
    setOpenRightSidebar: (state, action) => {
      state.openRightSidebar = action.payload
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setSelectedColumns: (state, action) => {
      state.selectedColumns = action.payload
    },
    setColumns: (state, action) => {
      state.columns = action.payload
    },
    setSelectedConstructions: (state, action) => {
      state.selectedConstructions = action.payload
    },
    setSelectedMachineElement: (state, action) => {
      state.selectedMachineElement = action.payload
    },
    setShowMapping: (state, action) => {
      state.showMapping = action.payload
    },
    setSavedFormats: (state, action) => {
      state.savedFormats = action.payload
    },
    setUsingFormatId: (state, action) => {
      state.usingFormatId = action.payload
    },
    resetAll: (state) => {
      Object.assign(state, initialState)
    },
    setShowReflectedModalType: (state, action: { type: string; payload: 'new' | 'override' | undefined }) => {
      state.showReflectedModalType = action.payload
    },
    setUploadedFiles: (state, action: { type: string; payload: UploadedFile[] }) => {
      state.uploadedFiles = action.payload
    },
    updateUploadedFileStatus: (state, action: { type: string; payload: { id: string; status: ProcessingStatus } }) => {
      const uploadedFiles = [...state.uploadedFiles]
      const index = uploadedFiles.findIndex((item) => item.file_id === action.payload.id)
      if (index > -1) {
        uploadedFiles[index] = { ...uploadedFiles[index], status: action.payload.status }
      }
      state.uploadedFiles = uploadedFiles
    },
    deleteUploadedFile: (state, action: { type: string; payload: string }) => {
      const uploadedFiles = [...state.uploadedFiles]
      const index = uploadedFiles.findIndex((item) => item.file_id === action.payload)
      if (index > -1) {
        uploadedFiles.splice(index, 1)
      }
      state.uploadedFiles = uploadedFiles
    },
    setShowNotificationOfFile: (state, action: { type: string; payload: { id: string; type: 'info' | 'error' } | undefined }) => {
      state.showNotificationOfFile = action.payload
    },
  },
})

// Actions
export const {
  setDataFile,
  setIsDeviceMappingFlow,
  setIsPreview,
  setTableInstance,
  setOpenRightSidebar,
  setActiveStep,
  setActiveTab,
  setSelectedColumns,
  setSelectedConstructions,
  setSelectedMachineElement,
  resetAll,
  setColumns,
  setShowMapping,
  setShowReflectedModalType,
  setSavedFormats,
  setUsingFormatId,
  setUploadedFiles,
  updateUploadedFileStatus,
  deleteUploadedFile,
  setShowNotificationOfFile,
  setCurrentFile,
} = deviceMappingSlice.actions

// Selector
export const deviceMappingState = (state: any) => state.deviceMapping as DeviceMachineState

// Reducer
export default deviceMappingSlice.reducer
