import React from 'react'

interface HistoryIconProps {
  active?: boolean
  isNotImplemented?: boolean
}

const HistoryIcon = ({ active, isNotImplemented }: HistoryIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.1499 22C5.6399 22 1.1499 17.51 1.1499 12C1.1499 6.49 5.6399 2 11.1499 2C14.6699 2 17.9699 3.89 19.7699 6.92C20.0499 7.4 19.8899 8.01 19.4199 8.29C18.9399 8.57 18.3299 8.41 18.0499 7.94C16.6199 5.51 13.9699 4 11.1599 4C6.7499 4 3.1599 7.59 3.1599 12C3.1599 16.41 6.7499 20 11.1599 20C14.9399 20 18.1199 17.36 18.9499 13.82L18.5699 14.2C18.1799 14.59 17.5499 14.59 17.1599 14.2C16.7699 13.81 16.7699 13.18 17.1599 12.79L19.1599 10.79C19.3499 10.6 19.5999 10.5 19.8699 10.5C20.1399 10.5 20.3899 10.61 20.5799 10.79L22.5799 12.79C22.9699 13.18 22.9699 13.81 22.5799 14.2C22.1899 14.59 21.5599 14.59 21.1699 14.2L20.9699 14C20.0399 18.55 15.9999 21.99 11.1699 21.99L11.1499 22ZM14.1499 15C13.9599 15 13.7699 14.95 13.5999 14.83L10.5999 12.83C10.3199 12.64 10.1499 12.33 10.1499 12V7C10.1499 6.45 10.5999 6 11.1499 6C11.6999 6 12.1499 6.45 12.1499 7V11.46L14.6999 13.16C15.1599 13.47 15.2799 14.09 14.9799 14.55C14.7899 14.84 14.4699 15 14.1499 15Z"
        fill={isNotImplemented ? '#A3A3A3' : active ? '#FFFFFF' : '#171717'}
      />
    </svg>
  )
}

export default HistoryIcon
