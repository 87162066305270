import React from 'react'

import '../styles/feature/loadingSpinner.scss'

interface LoadingSpinnerProps {
  size?: 'lg' | 'md' | 'sm'
}

const LoadingSpinner = ({ size = 'lg' }: LoadingSpinnerProps) => (
  <div className={`loading-spinner-container ${size}`}>
    <div className="loading-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default LoadingSpinner
