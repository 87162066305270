export type ISelectedColumns = Map<number, string>

export type ISelectedConstruction = {
  id?: string
  code?: number | string
  name?: string
}

export type ISelectedMachineElement = {
  id?: string
  code?: string
  mainCode?: string
  subCode?: string
  section?: string
  name?: string
}

export type RawEstimateFormat = {
  estimate_format_meta_id?: string
  estimate_id?: string
  name?: string
  draft?: boolean
  payload?: string
  created_at: string
  updated_at: string
}

export type EstimateFormat = {
  estimate_format_meta_id?: string | null
  estimate_id?: string
  name?: string
  draft?: boolean
  hiddenColumns?: string[][]
  dataFile?: FileContent
  selectedColumns?: ISelectedColumns
  created_at?: string
  updated_at?: string
  file_id?: string
}

export type SaveEstimateFormatItem = {
  estimate_format_meta_id: string | null
  name: string
  payload: string
  draft?: 1
  file_id?: string
}

export enum Step {
  SELECT_COLUMN = 1,
  SELECT_CONSTRUCTION_ELEMENT,
}

export enum LinkingTab {
  ELEMENT_COOPERATION_TAB = '1',
  LINKING_ELEMENT_TAB = '2',
}

export type FileContentHeader = {
  kind: string
  rowIndex: number
  columnIndex: number
  rowSpan: number
  columnSpan: number
  content: string
  spans: [
    {
      offset: number
      length: number
    }
  ]
}

export type FileContent = {
  meta?: {
    estimate_id: string
    status?: 'waiting'
    maxRowLength: number
  }
  data?: {
    headers: FileContentHeader[][]
    rows: string[][]
  }
}
