import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import PrivateRoute from '~/components/organisms/privateRoute'

import { ROUTER_PATH } from '~/consts/router'
import BasicLayout from '~/layout/BasicLayout/index'

import { Suspense } from './suspense'

const CreateProject = lazy(() => import('~/features/project/create'))
const ProjectList = lazy(() => import('~/features/project/list'))
const ListEstimate = lazy(() => import('~/features/project/listEstimate'))
const ListEstimateDetail = lazy(() => import('~/features/project/listEstimateDetail'))
const MValue = lazy(() => import('~/features/value'))

const BasicLayoutRouter: RouteObject = {
  path: '',
  element: (
    <PrivateRoute>
      <BasicLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: '',
      element: (
        <Suspense>
          <ProjectList />
        </Suspense>
      ),
    },
    {
      index: true,
      path: ROUTER_PATH.PROJECT.CREATE_PROJECT,
      element: (
        <Suspense>
          <CreateProject />
        </Suspense>
      ),
    },
    {
      index: true,
      path: ROUTER_PATH.PROJECT.LIST_ESTIMATE,
      element: (
        <Suspense>
          <ListEstimate />
        </Suspense>
      ),
    },
    {
      index: true,
      path: ROUTER_PATH.PROJECT.LIST_ESTIMATE_2,
      element: (
        <Suspense>
          <ListEstimateDetail />
        </Suspense>
      ),
    },
    {
      index: true,
      path: ROUTER_PATH.PROJECT.M_VALUE,
      element: (
        <Suspense>
          <MValue />
        </Suspense>
      ),
    },
  ],
}

export default BasicLayoutRouter
