import client from '~/api/axiosClient'
import { User } from '~/interfaces/user'

export const apiCreateUser = (request: User) =>
  client.post('/users', {
    user_id: request.user_id,
    email: request.email,
  })

export const apiGetMe = () => client.get('/users/me')
