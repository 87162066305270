import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import client from '~/api/axiosClient'
import { USER_ID } from '~/consts/storage'
import { getLocalStorage } from '~/utils/storage'

type ListenParams = {
  service?: string
  event?: string
  callback: (data: any) => void | Promise<void>
}

type ListenToAllParams = {
  service?: string
  events: string[]
  callback: (data: any) => void | Promise<void>
}

const baseUrl = import.meta.env.VITE_API_BASE_URL

class SocketService {
  private echo: Echo | null = null
  private userId = ''

  start() {
    this.userId = getLocalStorage(USER_ID, '')
    if (!this.userId) {
      this.stop()
      return
    }

    //@ts-ignore
    window.Pusher = Pusher

    const echoConfig = {
      broadcaster: 'pusher',
      key: '5VUd4xNcVO41',
      wsHost: 'origin-api.dev-mars.arent-planets.com',
      wsPath: '/socket.io',
      forceTLS: true,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      cluster: 'mt1',
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            client
              .post(`${baseUrl}/broadcasting/auth`, {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => {
                callback(null, response.data)
              })
              .catch((error) => {
                callback(error)
              })
          },
        }
      },
    }

    this.echo = new Echo(echoConfig)
  }

  stop() {
    if (this.echo) {
      this.echo.disconnect()
      this.echo = null
    }
  }

  listen({ service = `ocr.${this.userId}`, event = '.file.status.changed', callback }: ListenParams) {
    if (this.echo) {
      this.echo.private(service).listen(event, (e: any) => callback(e.message))
    }
  }

  listenToAll({ service = `ocr.${this.userId}`, events = [], callback }: ListenToAllParams) {
    if (this.echo) {
      const channel = this.echo.private(service)
      events.forEach((event) => {
        channel.listen(event, (e: any) => callback(e.message))
      })
    }
  }

  isRunning() {
    return !!this.echo
  }
}

export default SocketService
