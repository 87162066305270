import createSagaMiddleware from '@redux-saga/core'
import { configureStore } from '@reduxjs/toolkit'

import rootSaga from '~/redux/sagas'

import { rootReducer } from './reducers'

const sagaMiddleware = createSagaMiddleware()

const middlewares: any = [sagaMiddleware]

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      thunk: false,
      serializableCheck: {
        ignoredPaths: ['deviceMapping.selectedColumns', 'deviceMapping.tableInstance', 'deviceMapping.savedFormats'],
        ignoredActions: ['deviceMapping/setSelectedColumns', 'deviceMapping/setTableInstance', 'deviceMapping/setSavedFormats'],
      },
    }).concat(middlewares),
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
