export const ROUTER_PATH = {
  PROJECT: {
    LIST: '/',
    DETAIL: '/project/:idProject/:idEstimate',
    LIST_ESTIMATE: '/list-estimate/:id',
    LIST_ESTIMATE_2: '/list-estimate/item',
    CREATE_PROJECT: '/create-project',
    M_VALUE: '/m-value',
    MAPPING: '/project/:idProject/:idEstimate/mapping',
  },
  FILE: {
    FILE: '/file',
    PREVIEW: '/file/preview/:documentId',
  },
}
