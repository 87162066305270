import auth from '~/api/axiosAuth'
const authUrl = import.meta.env.VITE_API_AUTH_URL
import { logout } from '~/utils/user'

export const signIn = (dataSignIn) => auth.post('users/signIn', dataSignIn)
export const logoutApi = () => auth.post('signOut')
export const refreshTokenAuth = () => auth.post('refreshToken')

type PlanetsAuth = {
  profile: {
    userId: string
  }
}

export const doRefreshToken = async () => {
  // prevent refesh token in stagging
  if (authUrl?.indexOf('stg') > -1 || authUrl?.indexOf('stagging') > -1) {
    return
  }

  const urlRefreshToken = `${authUrl}refreshToken`

  const req = await fetch(urlRefreshToken, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const data: PlanetsAuth = await req.json()
  if (!data?.profile?.userId) {
    logout()
  }

  return data
}
