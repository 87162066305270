import Header from '@components/molecules/Header'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const BasicLayout = () => {
  const currentPath = useLocation().pathname
  return (
    <div className="container-layout">
      <Header />
      <div className={currentPath.includes('m-value') ? 'stretch-layout' : 'basic-layout'}>
        <Outlet />
      </div>
    </div>
  )
}

export default BasicLayout
