import React from 'react'

const ArrowDownBoldIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.886" height="6.333" viewBox="0 0 9.886 6.333">
      <g id="Frame_15" data-name="Frame 15" transform="translate(0.5 0.5)">
        <g id="down">
          <path id="down-2" data-name="down" d="M4.443,0,8.886-5.333H0Z" transform="translate(0 5.333)" fill="#171717" stroke="#171717" />
        </g>
      </g>
    </svg>
  )
}

export default ArrowDownBoldIcon
