import React from 'react'

const ArrowLeftIcon = () => {
  return (
    <svg id="icon-navigation-chevron_left_24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Boundary" width="24" height="24" fill="none" />
      <path id="_Color" data-name=" ↳Co lor" d="M7.4,1.41,5.992,0,0,6l5.992,6L7.4,10.59,2.826,6Z" transform="translate(8 6)" />
    </svg>
  )
}

export default ArrowLeftIcon
