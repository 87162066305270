export const MAPBOXGL_TOKEN = import.meta.env.VITE_MAPBOXGL_TOKEN
export const MAP_TEXT_PLUGIN = import.meta.env.VITE_MAPBOXGL_TEXT_PLUGIN
export const MAP_STYLE = import.meta.env.VITE_MAPBOXGL_STYLE
export const SERVER_AUTH = import.meta.env.VITE_SERVER_AUTH

export const DEFAULT_LANGUAGE = 'ja'

export const LANGUAGE_STORE_KEY = 'by_current_lang'

export const AUTH_USER_TOKEN = 'auth_user_token'

export const AUTH_REMEMBER_EXPIRES = 30

export const DATE = 'YYYY/MM/DD'

export const DATE_TIME = 'YYYY/MM/DD hh:mm'

export const DUMMY_CURVED_CHART = [[0, 0, '', '', 0, 0, 0, '', '', '']]

export const DUMMY_LINE_CHART = [
  {
    source: [[0, 0]],
  },
  {
    transform: {
      type: 'ecStat:regression',
    },
  },
]

export const YEAR = 'year'

export const NOTIFICATION_KEY = 'notification'

export const ADD_DEVICE_FORMAT_LENGTH = {
  MAIN_CODE_A: 2,
  SECTION_NUMBER: 1,
  MAIN_CODE_B: 2,
  SUB_CODE: 2,
}

export const RULE_CATEGORY = {
  CATEGORY_10: { name: 'M数量', value: 10 },
  CATEGORY_20: { name: '工事区分', value: 20 },
  CATEGORY_30: { name: '施工エリア', value: 30 },
  CATEGORY_40: { name: '仕様', value: 40 },
  CATEGORY_50: { name: '建物用途', value: 50 },
}

export const COMPARATIVE = {
  COMPARATIVE_LT: '未満',
  COMPARATIVE_GTE: '以上',
  COMPARATIVE_EQ: 'と一致',
  COMPARATIVE_NE: '以外',
}

export const SELECT_COST_TYPE = {
  HANGING_RATE: '10',
  M_VALUE: '20',
  ACTUAL_VALUE: '30',
}

export const SELECT_COST_TYPE_TITLE = {
  CATEGORY_10: '掛率',
  CATEGORY_20: 'M(入力値)',
  CATEGORY_30: 'M(実績値)',
}

export const OPTION_ECOST_TYPE = [
  {
    id: 1,
    label: '掛率',
    value: 10,
  },
  {
    id: 2,
    label: 'M(入力値)',
    value: 20,
  },
  {
    id: 3,
    label: 'M(実績値)',
    value: 30,
  },
]

export const OPTION_RULE_TYPE = [
  {
    id: 1,
    label: 'M数量',
    value: 10,
  },
  {
    id: 2,
    label: '工事区分',
    value: 20,
  },
  {
    id: 3,
    label: '施工エリア',
    value: 30,
  },
  {
    id: 4,
    label: '仕様',
    value: 40,
  },
  {
    id: 5,
    label: '建物用途',
    value: 50,
  },
]

export const OPTION_COMPARATIVE = [
  {
    id: 1,
    label: 'と一致',
    value: 'eq',
  },
  {
    id: 2,
    label: '以外',
    value: 'ne',
  },
  {
    id: 3,
    label: '未満',
    value: 'lt',
  },
  {
    id: 4,
    label: '以上',
    value: 'gte',
  },
]

export const ADD_MACHINE_TYPE = {
  INDIVIDUAL_TYPE: { mainCodeA: '10', sectionNumber: '1' },
  AIR_CONDITION_TYPE: { mainCodeA: '10', sectionNumber: '2' },
  OUTSOURCE_COST_TYPE: { mainCodeA: '10', sectionNumber: '3' },
  EXPENSES_TYPE: { mainCodeA: '99', sectionNumber: '' },
}

export const DUMMY_FEATURE_MAP = {
  type: 'Feature',
  geometry: { type: 'Point', coordinates: [139.692044, 35.688221] },
}

export const EQUIPMENT_COST_TABS = {
  INDIVIDUAL: { id: 1, name: 'machine' },
  ELEMENT: { id: 2, name: 'machineInElement' },
  GROUP: { id: 3, name: 'machineInGroup' },
}

// Construction
export const CATEGORY1_CODE = {
  10: '空調',
  11: 'ユーティリティ',
  20: '衛生',
  30: '電気',
  40: '建築',
  90: '経費',
}
export const CATEGORY2_CODE = {
  10: '熱源機器設備',
  20: '空調機器設備',
  30: 'ダクト設備',
  40: '配管設備',
  50: '換気設備',
  60: '排煙設備',
  70: '自動制御設備',
  80: '中央監視設備',
  90: 'その他設備',
  100: '経費',
}
export const CATEGORY3_CODE = {
  10: '給気',
  20: '排気',
  30: '還気',
  40: '-',
  50: '-',
}
export const INDIRECT_COST_GROUP = {
  10: '総括間接費',
  20: '熱源・空調機器',
  30: '空調ダクト',
  40: '空調配管',
  50: '換気設備',
  60: '排煙設備',
  70: '自動制御',
  80: '衛生配管',
  90: '屋外衛生配管',
  100: '衛生器具',
}
export const FLUID_TYPE = {
  10: '冷温水',
  20: '低圧蒸気',
  30: '冷却水',
  40: '給水',
  50: '冷媒・液菅',
}

export const MACHINE_KEY = {
  all: 10,
  laborCost: 102,
  outSourceCost: 103,
  expenses: 994,
}

export const CONSTRUCTION_TABS_OPTIONS = [
  { label: '全て', value: '' },
  { label: '空調', value: 10 },
  { label: 'ユーティリティ', value: 11 },
  { label: '衛生', value: 20 },
  { label: '電気', value: 30 },
  { label: '建築', value: 40 },
]

export const DEFAULT_GROUP_CODE = '1'

export const HEADER_OFFICE_OPTION_ALL = { label: '全て', value: 'all' }

export const ESTIMATE_STATUS = {
  0: '見積中',
  1: '見積済',
}

export const BUTTON_TYPE = {
  filled: 'filled',
  danger: 'danger',
  outlined: 'outlined',
  text: 'text',
}

export const POSITION_SEGMENTED_BUTTON = {
  left: 'left',
  center: 'center',
  right: 'right',
}

export const FORM_RULES = {
  max_length: {
    input: { max: 255, message: '0～255で入力してください' },
    textarea: { max: 1000, message: '0～1000で入力してください' },
  },
}
