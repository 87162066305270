import { RightOutlined } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '~/components/atoms/Button'

interface Props {
  onClick: () => void
  fileName: string
  estimateName?: string
}

export const OcrFileNotification = ({ onClick, fileName, estimateName }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col flex-1">
        <span className="flex items-center text-white">
          <span className="text-base font-bold whitespace-nowrap overflow-ellipsis">{estimateName}</span>
          <span className="text-base font-bold whitespace-nowrap ml-2 flex-1">{t('mapping.ocr_notification_title')}</span>
        </span>

        <span className="flex items-center text-white">
          <span className="text-sm font-bold whitespace-nowrap overflow-ellipsis">{fileName}</span>
          <span className="text-sm ml-2 whitespace-nowrap flex-1">{t('mapping.processing_finished')}</span>
        </span>
      </div>
      <Button type="text" text={t('mapping.view_file')} suffixIcon={<RightOutlined />} onClick={onClick} />
    </div>
  )
}
