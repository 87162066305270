/* eslint-disable func-style */
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import { apiGetProject } from '~/api/project'
import { ProjectParams } from '~/interfaces/project'
import { SET_LOADING, RESET_MESSAGE } from '~/redux/reducers/app.slice'
import { setProjectInfo } from '~/redux/reducers/project.slice'

function* getProjectList(action: PayloadAction<ProjectParams>) {
  try {
    yield put(RESET_MESSAGE())
    yield put(SET_LOADING(true))
    const { payload } = action
    const response: AxiosResponse = yield apiGetProject(payload)
    console.log('response', response)
    yield put(setProjectInfo(response.data))
  } catch (err) {
    // AxiosError
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* mentorsSaga() {
  const filteredSagas: ForkEffect[] = [takeLatest('project/getProjectList', getProjectList)]
  yield all(filteredSagas)
}
