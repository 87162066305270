import React from 'react'

interface Props {
  width?: string
  height?: string
  color?: string
}

export const Loading = ({ width = '16', height = '16', color = '#009BE0' }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_15859_115372)">
        <path
          d="M8.00065 1.33398V4.00065M8.00065 12.0007V14.6673M4.00065 8.00065H1.33398M14.6673 8.00065H12.0007M12.7196 12.7196L10.834 10.834M12.7196 3.33395L10.834 5.21956M3.2817 12.7196L5.16732 10.834M3.2817 3.33395L5.16732 5.21956"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15859_115372">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
