import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AppState {
  isLoading: boolean
  message: {
    type: string
    content: string
  }
  socketStatus: 0 | 1 | 2
}

const initialState: AppState = {
  isLoading: false,
  message: {
    type: '',
    content: '',
  },
  socketStatus: 0,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SET_MESSAGE: (state, action: PayloadAction<any>) => {
      state.message = action.payload
    },
    RESET_MESSAGE: (state) => {
      state.message = initialState.message
    },
    SET_LOADING: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    SET_SOCKET_STATUS: (state, action: PayloadAction<0 | 1 | 2>) => {
      state.socketStatus = action.payload
    },
  },
})

// Actions
export const { SET_MESSAGE, RESET_MESSAGE, SET_LOADING, SET_SOCKET_STATUS } = appSlice.actions

// Selector
export const message = (state: any) => state.app as AppState

// Reducer
export default appSlice.reducer
