import React from 'react'

interface SlideOutIconProps {
  width?: string
  height?: string
}

const SortedAZ = (props: SlideOutIconProps) => {
  const width = props.width ?? '24'
  const height = props.height ?? '24'

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7138 19.7066L17.7088 15.7066L17.7928 15.6116C17.9419 15.4191 18.0156 15.1787 18.0002 14.9357C17.9847 14.6927 17.8811 14.4636 17.7088 14.2916L17.6138 14.2086C17.4213 14.0595 17.1809 13.9857 16.9379 14.0012C16.6949 14.0166 16.4658 14.1203 16.2938 14.2926L14.0018 16.5896V4.99958L13.9948 4.88258C13.9663 4.63914 13.8495 4.41463 13.6664 4.25166C13.4834 4.0887 13.2469 3.99864 13.0018 3.99858L12.8848 4.00558C12.6414 4.03425 12.4169 4.15133 12.2542 4.33458C12.0914 4.51782 12.0016 4.75447 12.0018 4.99958V16.5826L9.70877 14.2926L9.61377 14.2086C9.41276 14.0513 9.15919 13.9768 8.90503 14.0004C8.65086 14.024 8.41534 14.1439 8.24672 14.3355C8.0781 14.5271 7.98914 14.776 7.99807 15.0311C8.00701 15.2862 8.11316 15.5282 8.29477 15.7076L12.3008 19.7076L12.3948 19.7906C12.5873 19.9397 12.8276 20.0134 13.0706 19.998C13.3137 19.9825 13.5417 19.8789 13.7138 19.7066Z"
        fill="#171717"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default SortedAZ
