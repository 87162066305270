import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialListProjectQueryParams } from '~/features/project/list'

interface projectInfoType {
  filterProjectList: string | undefined
  projectInfo: any
  tableProjectParams: any
}

const initialState: projectInfoType = {
  projectInfo: {},
  filterProjectList: undefined,
  tableProjectParams: initialListProjectQueryParams,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectInfo: (state, action: PayloadAction<any>) => {
      state.projectInfo = action.payload
    },
    setFilterProjectList: (state, action: PayloadAction<any>) => {
      state.filterProjectList = action.payload
    },
    setTableProjectParams: (state, action: PayloadAction<any>) => {
      state.tableProjectParams = action.payload
    },
    resetTableProjectParams: (state) => {
      state.tableProjectParams = initialState.tableProjectParams
    },
  },
})

// Actions
export const { setProjectInfo, setFilterProjectList, setTableProjectParams, resetTableProjectParams } = projectSlice.actions

// Selector
export const projectIn = (state: any) => state.project

// Reducer
export default projectSlice.reducer
