import { Divider } from 'antd'
import { cloneDeep, get, isEmpty, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import DateRangeFilter, { DateRangeParamsType } from '~/components/molecules/TableFilters/DateRangeFilter'
import SortFilter, { Sort } from '~/components/molecules/TableFilters/SortFilter'
import TextFilter from '~/components/molecules/TableFilters/TextFilter'
import TableProject from '~/components/molecules/TableProject'

import { apiGetProject } from '~/api/project'
import CusPlusIcon from '~/assets/icons/CusPlusIcon'
import FilterIcon from '~/assets/icons/FilterIcon2'
import { HEADER_OFFICE_OPTION_ALL } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { projectIn, setTableProjectParams as setQueryParams } from '~/redux/reducers/project.slice'
import { DateFormat, formatDatetime } from '~/utils/datetimeUtils'
import { handleError, pushNotification } from '~/utils/helper'

import '~/assets/styles/feature/listProject.scss'

import type { ColumnType } from 'antd/es/table'

const initialState: any = {
  data: [],
  per_page: 10,
  prev_page_url: null,
  to: 0,
  total: 0,
  current_page: 1,
}

const PROJECT_TABLE_KEY = {
  PROPERTY_NUMBER: 'property_number',
  CORE_SYSTEM_PROJECT_ID: 'core_system_project_id',
  PROJECT_NAME: 'project_name',
  OWNER_NAME: 'owner_name',
  CONSTRUCTION_PERIOD_PERIOD_START_DATE: 'construction_period_start_date',
  CONSTRUCTION_PERIOD_PERIOD_END_DATE: 'construction_period_end_date',
  SALES_SECTION_NAME: 'sales_section_name',
  SALES_STAFF_NAME: 'sales_staff_name',
  ORDER_ACCURACY: 'order_accuracy',
  IMPORTANCE: 'importance',
}

export const initialListProjectQueryParams = {
  limit: 10,
  page: 1,
  filter: null,
  sort_by: null,
}

const ListProject = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { filterProjectList, tableProjectParams: queryParams } = useAppSelector(projectIn)

  const [projectList, setProjectList] = useState<any>(initialState)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sorts, setSorts] = useState<any[]>(get(queryParams, 'sort_by') ?? {})
  const [filters, setFilters] = useState<any>(get(queryParams, 'filter') ?? {})

  const { data, total, current_page, per_page, from, to } = projectList

  const isEmptySortsAndFilters = isEmpty(sorts) && isEmpty(filters)

  const getProject = async () => {
    if (isNil(filterProjectList)) return
    setIsLoading(true)
    try {
      const res = await apiGetProject({
        params: {
          ...queryParams,
          office_id: filterProjectList === HEADER_OFFICE_OPTION_ALL.value ? null : filterProjectList,
        },
      })
      setProjectList(res.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigate = (record) => {
    navigate(
      generatePath(ROUTER_PATH.PROJECT.LIST_ESTIMATE, {
        id: `${record.id}`,
      }),
      {
        state: record,
      }
    )
  }

  const handleDetail = () => {
    navigate(ROUTER_PATH.PROJECT.CREATE_PROJECT)
  }

  const handleAction = (pagination: any, _: any) => {
    let queryParamsClone = { ...queryParams }
    queryParamsClone = {
      ...queryParamsClone,
      page: queryParams.page === pagination.current ? 1 : pagination.current,
    }
    dispatch(setQueryParams(queryParamsClone))
  }

  const handleSortsAndFilters = () => {
    let queryParamsClone = { ...queryParams }
    queryParamsClone = {
      ...queryParamsClone,
      filter: !isEmpty(filters) ? filters : null,
      sort_by: !isEmpty(sorts) ? sorts : null,
    }
    dispatch(setQueryParams(queryParamsClone))
  }

  const putOrRemoveValueInObj = (obj: any, key: string, value: string | DateRangeParamsType | Sort | null) => {
    const clonedObj = cloneDeep(obj)
    if (isNil(value) && clonedObj[key]) {
      delete clonedObj[key]
    } else {
      clonedObj[key] = value
    }
    return clonedObj
  }

  const handleSort = (dataIndex: string) => (sortValue: Sort | null) => {
    setSorts((prevState: any) => {
      return putOrRemoveValueInObj(prevState, dataIndex, sortValue)
    })
  }

  const handleSearch = (dataIndex: string) => (searchValue: string | DateRangeParamsType | null) => {
    setFilters((prevState: any) => {
      return putOrRemoveValueInObj(prevState, dataIndex, searchValue)
    })
  }

  const handleReset = () => {
    setFilters({})
    setSorts({})
  }

  const getColumnSearchProps = (dataIndex: string): ColumnType<any> => ({
    filterDropdown: () => {
      const isDate = [PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_START_DATE, PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_END_DATE].includes(dataIndex)
      return (
        <div className="p-4" onKeyDown={(e) => e.stopPropagation()}>
          <SortFilter value={get(sorts, dataIndex)} onChange={handleSort(dataIndex)} />
          <Divider style={{ borderColor: '#A3A3A3' }} />
          {isDate ? (
            <DateRangeFilter from={get(filters, `${dataIndex}.from`)} to={get(filters, `${dataIndex}.to`)} onChange={handleSearch(dataIndex)} />
          ) : (
            <TextFilter value={get(filters, dataIndex)} onChange={handleSearch(dataIndex)} />
          )}
        </div>
      )
    },
    filterIcon: () => <FilterIcon active={sorts[dataIndex] || filters[dataIndex]} />,
  })

  const dividerCol = {
    title: <Divider type="vertical" className="h-6 border-l-2 border-{#D4D4D4}" />,
    render: () => <Divider type="vertical" className="h-7 border-l-2 border-{#D4D4D4}" />,
    width: 3,
  }

  const columns: any = [
    {
      title: t('project_list.property_no'),
      dataIndex: PROJECT_TABLE_KEY.PROPERTY_NUMBER,
      key: PROJECT_TABLE_KEY.PROPERTY_NUMBER,
      width: 140,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.PROPERTY_NUMBER),
    },
    {
      title: t('project_list.project_id'),
      dataIndex: PROJECT_TABLE_KEY.CORE_SYSTEM_PROJECT_ID,
      key: PROJECT_TABLE_KEY.CORE_SYSTEM_PROJECT_ID,
      width: 200,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.CORE_SYSTEM_PROJECT_ID),
    },
    {
      title: t('project_list.project_name'),
      dataIndex: PROJECT_TABLE_KEY.PROJECT_NAME,
      key: PROJECT_TABLE_KEY.PROJECT_NAME,
      width: 220,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.PROJECT_NAME),
    },
    {
      title: t('project_list.customer_name'),
      dataIndex: PROJECT_TABLE_KEY.OWNER_NAME,
      key: PROJECT_TABLE_KEY.OWNER_NAME,
      width: 220,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.OWNER_NAME),
    },
    dividerCol,
    {
      title: t('project_list.completion'),
      dataIndex: PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_START_DATE,
      key: PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_START_DATE,
      width: 120,
      render: (record) => formatDatetime(record, DateFormat.DATE_SLASH),
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_START_DATE),
    },
    {
      title: t('project_list.quote_level'),
      dataIndex: PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_END_DATE,
      key: PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_END_DATE,
      width: 120,
      render: (record) => formatDatetime(record, DateFormat.DATE_SLASH),
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.CONSTRUCTION_PERIOD_PERIOD_END_DATE),
    },
    dividerCol,
    {
      title: t('project_list.sale_selection'),
      dataIndex: PROJECT_TABLE_KEY.SALES_SECTION_NAME,
      key: PROJECT_TABLE_KEY.SALES_SECTION_NAME,
      width: 180,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.SALES_SECTION_NAME),
    },
    {
      title: t('project_list.sale_representative'),
      dataIndex: PROJECT_TABLE_KEY.SALES_STAFF_NAME,
      key: PROJECT_TABLE_KEY.SALES_STAFF_NAME,
      width: 180,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.SALES_STAFF_NAME),
    },
    dividerCol,
    {
      title: t('project_list.order_probability'),
      dataIndex: PROJECT_TABLE_KEY.ORDER_ACCURACY,
      key: PROJECT_TABLE_KEY.ORDER_ACCURACY,
      render: (record) => <div className="flex justify-between items-center">{record}</div>,
      width: 100,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.ORDER_ACCURACY),
    },
    {
      title: t('project_list.importance'),
      dataIndex: PROJECT_TABLE_KEY.IMPORTANCE,
      key: PROJECT_TABLE_KEY.IMPORTANCE,
      render: (record) => <div className="flex justify-between items-center">{record}</div>,
      width: 100,
      ellipsis: true,
      ...getColumnSearchProps(PROJECT_TABLE_KEY.IMPORTANCE),
    },
  ]

  useEffect(() => {
    getProject()
  }, [queryParams, filterProjectList])

  useEffect(() => {
    handleSortsAndFilters()
  }, [sorts, filters])

  return (
    <div className="table-padding project-list w-10/12 mx-auto">
      <div className="pt-12 mb-8 flex justify-between items-center">
        <span className="text-2xl font-bold">{t('project_list.title')}</span>
        <Button
          text={t('project_list.new_project')}
          prefixIcon={<CusPlusIcon color="#ffffff" />}
          // type={BUTTON_TYPE.outlined}
          onClick={handleDetail}
        />
      </div>

      <TableProject
        columnsDefine={columns}
        data={data}
        total={total}
        from={from}
        to={to}
        current_page={current_page}
        per_page={per_page}
        loading={isLoading}
        onRow={(record) => {
          return {
            onClick: () => handleNavigate(record),
            className: 'cursor-pointer',
          }
        }}
        handleAction={handleAction}
        disabledReset={isEmptySortsAndFilters}
        handleReset={handleReset}
      />
    </div>
  )
}

export default ListProject
