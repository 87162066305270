import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DataTypeConstruction } from '~/interfaces/construction'

interface constructionStateType {
  dataTable: DataTypeConstruction[]
  dataTableInDrawer: DataTypeConstruction[]
  dataSelectKeys: Record<number, number[]>
}

const initialState: constructionStateType = {
  dataTable: [],
  dataTableInDrawer: [],
  dataSelectKeys: {},
}

export const constructionSlice = createSlice({
  name: 'construction',
  initialState,
  reducers: {
    setDataConstructionDrawer: (state, action: PayloadAction<any>) => {
      state.dataTableInDrawer = [...action.payload]
    },
    setDataConstruction: (state, action: PayloadAction<any>) => {
      state.dataTable = [...action.payload]
    },
    setConstructionSelects: (state, action: PayloadAction<any>) => {
      state.dataSelectKeys = { ...action.payload }
    },
    reset: () => initialState,
  },
})

// Actions
export const { setDataConstructionDrawer, setDataConstruction, setConstructionSelects, reset } = constructionSlice.actions

// Selector
export const constructions = (state: any) => state.construction

// Reducer
export default constructionSlice.reducer
