import { Checkbox, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'

import DatePickerIcon from '~/assets/icons/DatePickerIcon'
import { DATE } from '~/consts/common'
import { DateFormat, formatDatetime, isAfter, isBefore } from '~/utils/datetimeUtils'

enum DateRange {
  FROM = 'from',
  TO = 'to',
}

export type DateRangeParamsType = { from: string | null; to: string | null }

interface Props {
  from?: string | null
  to?: string | null
  onChange?: (value: DateRangeParamsType | null) => void
}

const initialValues = { from: '', to: '' }

const DateRangeFilter = ({ from: inputFrom = initialValues.from, to: inputTo = initialValues.to, onChange }: Props) => {
  const [value, setValue] = useState<DateRangeParamsType>({ from: inputFrom, to: inputTo })
  const [active, setActive] = useState<boolean>(Boolean(inputFrom || inputTo))

  const disabledStartDate = (current: any) => current && isAfter(current, value.to)
  const disabledEndDate = (current: any) => current && isBefore(current, value.from)

  const handleChange = (dateValue: any, type = DateRange.FROM) => {
    if (!dateValue) return

    setValue({
      from: type === DateRange.FROM ? dateValue : value.from,
      to: type === DateRange.TO ? dateValue : value.to,
    })

    if (onChange)
      onChange({
        from: formatDatetime(type === DateRange.FROM ? dateValue?.toString() : value.from, DateFormat.DATE),
        to: formatDatetime(type === DateRange.TO ? dateValue?.toString() : value.to, DateFormat.DATE),
      })
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setActive(checked)
    if (onChange) onChange(checked ? value : null)
  }

  // handle reset value
  useEffect(() => {
    if (inputFrom || inputTo) return
    setValue(initialValues)
    setActive(false)
  }, [inputFrom, inputTo])

  return (
    <>
      <Checkbox className="py-2" checked={active} onChange={handleCheck}>
        期間
      </Checkbox>
      <div className="flex pl-6 justify-between items-center">
        <DatePicker
          disabled={!active}
          className="w-10/12 rounded border-[#A3A3A3]"
          format={DATE}
          disabledDate={disabledStartDate}
          value={value.from}
          onChange={(dateValue) => {
            handleChange(dateValue, DateRange.FROM)
          }}
          suffixIcon={<DatePickerIcon />}
        />
        <span className="text-xl mx-2">～</span>
        <DatePicker
          disabled={!active}
          className="w-10/12 rounded border-[#A3A3A3]"
          format={DATE}
          value={value.to}
          disabledDate={disabledEndDate}
          onChange={(dateValue) => {
            handleChange(dateValue, DateRange.TO)
          }}
          suffixIcon={<DatePickerIcon />}
        />
      </div>
    </>
  )
}

export default DateRangeFilter
