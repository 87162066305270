import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const FileViewer = lazy(() => import('~/features/file-viewer'))

import PrivateRoute from '~/components/organisms/privateRoute'

import { ROUTER_PATH } from '~/consts/router'
import EmptyLayout from '~/layout/EmptyLayout'

import { Suspense } from './suspense'

const EmptyLayoutRouter: RouteObject = {
  path: ROUTER_PATH.FILE.FILE,
  element: (
    <PrivateRoute>
      <EmptyLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: ROUTER_PATH.FILE.PREVIEW,
      element: (
        <Suspense>
          <FileViewer />
        </Suspense>
      ),
    },
  ],
}

export default EmptyLayoutRouter
