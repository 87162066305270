import { Tooltip } from 'antd'
import React, { cloneElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import EstimateIcon from '~/assets/icons/MenuIcon/EstimateIcon'
import FolderConstructionIcon from '~/assets/icons/MenuIcon/FolderConstructionIcon'
import HistoryIcon from '~/assets/icons/MenuIcon/HistoryIcon'
import ProjectInfoIcon from '~/assets/icons/MenuIcon/ProjectInfoIcon'
import TableIcon from '~/assets/icons/MenuIcon/TableIcon'
import { className } from '~/utils/stringUtils'

interface SidebarMenuProps {
  onChange?: (item: { id: string; path?: string }) => void
  tab?: string
}

const SidebarMenu = ({ onChange, tab }: SidebarMenuProps) => {
  const { t } = useTranslation()
  const params = useParams()

  const [hoveredItemId, setHoveredItemId] = useState(null)

  const hoveredBoxStyle = 'hover:bg-blue-100 hover:text-white hover:cursor-pointer'

  const menuItems = [
    { id: '1', icon: <ProjectInfoIcon />, text: t('tab.project_info_icon'), path: '', activePath: [params.idEstimate] },
    { id: '2', icon: <FolderConstructionIcon />, text: t('tab.folder_construction_icon'), path: 'constructions', activePath: ['constructions'] },
    { id: '3', icon: <TableIcon />, text: t('tab.table_icon'), path: 'machines', activePath: ['machines', 'mapping'] },
    { id: '4', icon: <EstimateIcon />, text: t('tab.estimate_icon') },
    { id: '5', icon: <HistoryIcon />, text: t('tab.history_icon') },
  ]

  return (
    <div className="flex flex-col gap-3 px-3 py-6">
      {menuItems.map((item) => {
        const isNotImplemented = ['4', '5'].includes(item.id)
        const active = item.activePath?.includes(tab)
        const boxStyle = active ? 'bg-blue-100 text-white' : 'text-black-100'
        const isActiveIcon = item.id === hoveredItemId || active
        const currentNotImplemented = isNotImplemented ? '!bg-transparent !text-[#A3A3A3] !cursor-default' : ''

        return (
          <Tooltip key={item.id} placement="right" title={isNotImplemented ? t('common.current_not_implemented') : null}>
            <div
              className={className(
                'flex flex-col justify-center gap-1 ',
                'items-center text-center p-2 rounded-lg',
                `${boxStyle} ${isNotImplemented ? currentNotImplemented : hoveredBoxStyle}`
              )}
              onMouseEnter={() => setHoveredItemId(item.id)}
              onMouseLeave={() => setHoveredItemId(null)}
              onClick={() => {
                if (isNotImplemented) return
                onChange && onChange(item)
              }}
            >
              {cloneElement(item.icon, {
                active: isNotImplemented ? false : isActiveIcon,
                isNotImplemented: isNotImplemented,
              })}
              <span className={`text-xs w-[35px] ${isNotImplemented ? 'text-[#A3A3A3]' : ''}`}>{item.text}</span>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default SidebarMenu
