import React from 'react'

interface ProjectInfoIconProps {
  active?: boolean
}

const ProjectInfoIcon = ({ active }: ProjectInfoIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 22C2.45 22 2 21.55 2 21V5C2 3.35 3.35 2 5 2H14C15.65 2 17 3.35 17 5V9.06978C20.3928 9.55209 23.01 12.476 23.01 16C23.01 19.86 19.87 23 16.01 23C12.15 23 9.00999 19.86 9.00999 16C9.00999 12.4829 11.6169 9.56358 15 9.07265V5C15 4.45 14.55 4 14 4H5C4.45 4 4 4.45 4 5V21C4 21.55 3.55 22 3 22ZM6 6H8.45V8.45H6V6ZM10.91 6H13.36V8.45H10.91V6ZM8.45 10.91H6V13.36H8.45V10.91ZM6 15.8101H8.45V18.2601H6V15.8101ZM11.01 16C11.01 13.24 13.25 11 16.01 11C18.77 11 21.01 13.24 21.01 16C21.01 18.76 18.77 21 16.01 21C13.25 21 11.01 18.76 11.01 16ZM16.06 19.47C15.51 19.47 15.06 19.02 15.06 18.47V15.97C15.06 15.42 15.51 14.97 16.06 14.97C16.61 14.97 17.06 15.42 17.06 15.97V18.47C17.06 19.02 16.61 19.47 16.06 19.47ZM16.065 14.68C16.0667 14.68 16.0683 14.6801 16.07 14.6801H16.06C16.0617 14.6801 16.0633 14.68 16.065 14.68ZM15.06 13.6801C15.06 14.2284 15.5172 14.6773 16.065 14.68C16.6127 14.6773 17.06 14.2284 17.06 13.6801C17.06 13.1301 16.61 12.6801 16.06 12.6801H16.05C15.5 12.6801 15.06 13.1301 15.06 13.6801Z"
        fill={active ? '#FFFFFF' : '#171717'}
      />
    </svg>
  )
}

export default ProjectInfoIcon
