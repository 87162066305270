import { useEffect } from 'react'

import { apiGetMe } from '~/api/user'
import { USER_ID } from '~/consts/storage'
import { handleError, pushNotification } from '~/utils/helper'
import { setLocalStorage } from '~/utils/storage'

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const getUserInfo = async () => {
    try {
      const response = await apiGetMe()
      const user_id = response?.data?.data?.user_id

      if (user_id) {
        setLocalStorage(USER_ID, user_id)
      }
    } catch (error) {
      pushNotification(handleError(error), 'error', 10)
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  return children
}

export default PrivateRoute
