import { combineReducers } from '@reduxjs/toolkit'

import app from '~/redux/reducers/app.slice'
import construction from '~/redux/reducers/construction.slice'
import deviceMapping from '~/redux/reducers/deviceMapping.slice'
import machine from '~/redux/reducers/machine.slice'
import project from '~/redux/reducers/project.slice'

export const rootReducer = combineReducers({
  app,
  construction,
  deviceMapping,
  machine,
  project,
})
