import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface machineStateType {
  dataTable: any[]
  metaDataTable: any
  infoMachine: any
  filterConstruction: any[]
  selectedConstruction: string[]
  loaded: boolean
}

const initialState: machineStateType = {
  dataTable: [],
  metaDataTable: {},
  infoMachine: {},
  filterConstruction: [],
  selectedConstruction: [],
  loaded: false,
}
export const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    setDataListMachine: (state, action: PayloadAction<any>) => {
      state.dataTable = [...action.payload]
    },
    setMetaDataTable: (state, action: PayloadAction<any>) => {
      state.metaDataTable = { ...action.payload }
    },
    setInfoMachine: (state, action: PayloadAction<any>) => {
      state.infoMachine = action.payload
    },
    setFilterConstruction: (state, action: PayloadAction<any>) => {
      state.filterConstruction = [...action.payload]
    },
    setSelectedConstruction: (state, action: PayloadAction<any>) => {
      state.selectedConstruction = [...action.payload]
      state.loaded = true
    },
    resetLoaded: (state) => {
      state.loaded = false
    },
    reset: (state) => {
      Object.assign(state, initialState)
    },
  },
})

// Actions
export const { setDataListMachine, setMetaDataTable, setInfoMachine, setFilterConstruction, setSelectedConstruction, resetLoaded, reset } = machineSlice.actions

// Selector
export const machines = (state: any) => state.machine

// Reducer
export default machineSlice.reducer
