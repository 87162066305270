import { RightOutlined } from '@ant-design/icons'
import Button from '@components/atoms/Button'
import React from 'react'

import ArrowLeft from '~/assets/icons/ArrowLeftIcon'

interface HeaderProps {
  onBack?: () => void
  estimateName?: string
}

const Header = ({ onBack, estimateName }: HeaderProps) => {
  return (
    <div className="flex justify-between items-center border-b border-light-100 py-4 px-10 bg-white">
      <div className="flex items-center">
        <Button type="text" className="pl-0" onClick={onBack} prefixIcon={<ArrowLeft />} />
        <span className="text-[1.375rem] font-bold text-black-100">{estimateName}</span>
      </div>
      <Button type="text" className="hidden pr-0" onClick={console.log} text="共通資料一覧" suffixIcon={<RightOutlined className="mb-1" />} />
    </div>
  )
}

export default Header
