import React from 'react'

import LoadingSpinner from '~/assets/icons/LoadingSpinner'

const PageLoading = () => {
  return (
    <div className="fixed left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-white/40 z-[1100]">
      <LoadingSpinner size="md" />
    </div>
  )
}

PageLoading.defaultProps = {
  show: true,
}

export default PageLoading
