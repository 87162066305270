export const getSessionStorage = <T = any>(key: string, fallbackValue = {}): T => {
  if (typeof sessionStorage === 'undefined') {
    return fallbackValue as unknown as T
  }
  const data = sessionStorage.getItem(key)
  try {
    return (JSON.parse(data as string) || fallbackValue) as T
  } catch (e) {
    return (data || fallbackValue) as unknown as T
  }
}

export const setSessionStorage = (key: string, value?: string | boolean | number | object) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = <T = any>(key: string, fallbackValue = {}): T => {
  if (typeof localStorage === 'undefined') {
    return fallbackValue as unknown as T
  }
  const data = localStorage.getItem(key)
  try {
    return (JSON.parse(data as string) || fallbackValue) as T
  } catch (e) {
    return (data || fallbackValue) as unknown as T
  }
}

export const setLocalStorage = (key: string, value?: string | boolean | number | object) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const clearCache = (urn?: string) => {
  navigator.serviceWorker.ready.then((req: any) => {
    return new Promise((resolve, reject) => {
      const channel = new MessageChannel()
      channel.port1.onmessage = (event) => {
        if (event.data.error) {
          reject(event.data)
        } else {
          resolve(event.data)
        }
      }
      req.active.postMessage(
        {
          operation: 'CLEAR_CACHE',
          urn,
        },
        [channel.port2]
      )
    })
  })
}
