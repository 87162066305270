import { notification, Typography } from 'antd'
import { get, isNil } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import SidebarMenu from '~/components/molecules/SidebarMenu'

import { apiGetEstimate } from '~/api/project'
import ArrowLeft from '~/assets/icons/ArrowLeftIcon'
import { ESTIMATE_STATUS, NOTIFICATION_KEY } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import DeviceMappingHeader from '~/features/deviceMapping/header'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { deviceMappingState, resetAll as resetDeviceMappingState, setCurrentFile, setShowNotificationOfFile } from '~/redux/reducers/deviceMapping.slice'
import { reset as resetMachineState } from '~/redux/reducers/machine.slice'
import { DateFormat, formatDatetime } from '~/utils/datetimeUtils'
import { handleError, pushNotification } from '~/utils/helper'
import { setLocalStorage } from '~/utils/storage'

import { OcrFileNotification } from './OcrFileNotification'

const { Title } = Typography

const demo = {
  isEdit: false,
  count: 0,
}

const defaultNoDataText = '---'

export const handleDemo = (state, action) => {
  switch (action.type) {
    case 'increment':
      state.count += action.payload
      break
  }
}

export const Context = React.createContext<any>(demo)

interface TabProps {
  setHeaderTitle?: (title: string) => void
}

const Tab = ({ setHeaderTitle }: TabProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()
  const [estimateName, setEstimateName] = useState<string>()

  const { showNotificationOfFile, uploadedFiles, isDeviceMappingFlow, openRightSidebar } = useAppSelector(deviceMappingState)

  const [projectEstimateData, setProjectEstimateData] = useState(null)

  const location = useLocation()
  const tab = useMemo(() => {
    return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  }, [location.pathname])

  const getDetail = async () => {
    try {
      const res = await apiGetEstimate({
        idProject: params.idProject,
        idEstimate: params.idEstimate,
      })
      if (res?.data) {
        const estimateName = get(res.data, 'data.estimate_name')
        const data = {
          estimate_name: get(res.data, 'data.estimate_name') ?? '',
          customer_name: get(res.data, 'data.customer_name') ?? '',
          submission_deadline: get(res.data, 'data.submission_deadline') ?? '',
          submission_date: get(res.data, 'data.submission_date') ?? '',
          estimate_status: get(res.data, 'data.estimate_status') ?? '',
          project_name: get(res.data, 'meta.project_name') ?? '',
        }
        setProjectEstimateData(data)
        setEstimateName(estimateName)
      }
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  const openDeviceMapping = (fileId?: string) => {
    if (!fileId) return

    const index = uploadedFiles.findIndex((item) => item.file_id === fileId)
    dispatch(setCurrentFile({ id: fileId, name: `${index + 1}${t('mapping.page')}` }))
    notification.close(fileId)

    navigate(
      generatePath(ROUTER_PATH.PROJECT.MAPPING, {
        idProject: `${params.idProject}`,
        idEstimate: `${params.idEstimate}`,
      })
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      await getDetail()
    }

    fetchData()
  }, [params])

  useEffect(() => {
    if (!projectEstimateData) return
    setLocalStorage('projectEstimateData', projectEstimateData)
    if (setHeaderTitle) setHeaderTitle(projectEstimateData?.project_name ?? '')
  }, [projectEstimateData])

  useEffect(() => {
    if (!showNotificationOfFile) return
    const { id, type } = showNotificationOfFile
    const fileIndex = uploadedFiles.findIndex((file) => file.file_id === id)
    if (fileIndex > -1) {
      const fileName = `${fileIndex + 1}${t('mapping.page')}`
      pushNotification(
        <OcrFileNotification
          onClick={() => {
            if (type === 'info') {
              openDeviceMapping(id)
            }
          }}
          fileName={fileName}
          estimateName={estimateName}
        />,
        type,
        60,
        'notification-device-mapping',
        id
      )
      dispatch(setShowNotificationOfFile(undefined))
    }
  }, [showNotificationOfFile, uploadedFiles])

  useEffect(() => {
    return () => {
      dispatch(resetMachineState())
      dispatch(resetDeviceMappingState())
      notification.close(NOTIFICATION_KEY)
    }
  }, [])

  const renderHeader = () => {
    return isDeviceMappingFlow ? (
      <DeviceMappingHeader estimateName={estimateName} />
    ) : (
      <div className="flex justify-between items-center border-b border-light-100 py-4 px-10 bg-white">
        <div className="flex items-center">
          <Button
            type="text"
            offFocus
            onClick={() => {
              navigate(
                generatePath(ROUTER_PATH.PROJECT.LIST_ESTIMATE, {
                  id: `${params.idProject}`,
                })
              )
            }}
            className="button-navigate"
            prefixIcon={<ArrowLeft />}
          />
          <Title level={3} className="title-tab ml-4">
            {get(projectEstimateData, 'estimate_name') || defaultNoDataText}
          </Title>
        </div>
        <div className="flex justify-between items-center space-x-24 mt-2">
          <div className="flex space-x-10">
            <div className="space-x-4">
              <span>{t('tab.where_to_submit')}</span>
              <span className="text-lg text-black-100 font-bold">{get(projectEstimateData, 'customer_name') || defaultNoDataText}</span>
            </div>
            <div className="space-x-4">
              <span>{t('tab.quotation_submission_deadline')}</span>
              <span className="text-lg text-black-100 font-bold">
                {formatDatetime(get(projectEstimateData, 'submission_deadline'), DateFormat.DATE_SLASH, false, defaultNoDataText)}
              </span>
            </div>
            <div className="space-x-4">
              <span>{t('tab.quotation_submission_date')}</span>
              <span className="text-lg text-black-100 font-bold">
                {formatDatetime(get(projectEstimateData, 'submission_date'), DateFormat.DATE_SLASH, false, defaultNoDataText)}
              </span>
            </div>
          </div>
          <div className={`text-lg text-black-100 font-bold ${isNil(get(projectEstimateData, 'estimate_status')) ? 'invisible' : ''}`}>
            <span>{get(ESTIMATE_STATUS, get(projectEstimateData, 'estimate_status'))}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Context.Provider value={demo}>
      <div className="h-[calc(100%-64px)] w-full flex flex-col">
        {renderHeader()}
        <div className="flex h-[calc(100%-69px)]">
          {!openRightSidebar && (
            <div className="w-[5.25rem] bg-white border-r border-light-100">
              <SidebarMenu
                tab={tab}
                onChange={(item) => {
                  if (item.path === '' || item.path) {
                    navigate(item.path)
                  }
                }}
              />
            </div>
          )}
          <div className="h-full w-full overflow-auto">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </Context.Provider>
  )
}
export default Tab
