import axios from 'axios'

import { EXP_IN, INTERVAL_TIME_REFRESH, IS_LOGGED_IN } from '~/consts/storage'
import { errorLog, infoLog } from '~/utils/loggerUtils'
import { getLocalStorage } from '~/utils/storage'
import { logout } from '~/utils/user'

import { refreshTokenAuth } from './authenApi'

const baseAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
  timeout: 600000,
})

baseAxios.defaults.headers.get = {
  ...baseAxios.defaults.headers.common,
  'Content-Type': 'application/json; charset=UTF-8',
}

baseAxios.interceptors.request.use((config: any) => {
  const isLoggedIn = getLocalStorage(IS_LOGGED_IN) as boolean
  if (!isLoggedIn) {
    return
  }

  const handleRefreshTokenAuth = async () => {
    try {
      const { data } = await refreshTokenAuth()
      if (!data.profile.userId) {
        logout()
      }
    } catch (error) {
      logout()
    }
  }
  const checkRefreshToken = getLocalStorage(EXP_IN)
  if (Number(checkRefreshToken) - INTERVAL_TIME_REFRESH < Date.now()) {
    handleRefreshTokenAuth()
  }
  return config
})

baseAxios.interceptors.response.use(
  async (response) => {
    const error = response.data?.errors
    if (error) {
      errorLog(JSON.stringify(error))
    }

    return response
  },
  async (error) => {
    if ([401, 403].includes(error.response.status)) {
      logout()
      return
    }

    infoLog(JSON.stringify(error))

    return Promise.reject(error)
  }
)

export default baseAxios
