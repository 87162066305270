import { SearchOutlined } from '@ant-design/icons'
import { Checkbox, Input } from 'antd'
import React, { useEffect, useState } from 'react'

interface Props {
  value?: string
  onChange?: (value: string | null) => void
}

const initialValue = ''

const TextFilter = ({ value: inputValue = initialValue, onChange }: Props) => {
  const [value, setValue] = useState<string>(inputValue)
  const [active, setActive] = useState(Boolean(inputValue))

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setActive(checked)
    if (onChange) onChange(checked ? value : null)
  }

  // handle reset value
  useEffect(() => {
    if (inputValue) return
    setValue(initialValue)
    setActive(false)
  }, [inputValue])

  return (
    <>
      <Checkbox className="py-2" checked={active} onChange={handleCheck}>
        テキストフィルター
      </Checkbox>
      <div className="w-72 pl-6">
        <Input
          disabled={!active}
          size="large"
          prefix={<SearchOutlined />}
          placeholder="検索"
          value={value as string}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            if (onChange) onChange(value)
          }}
        />
      </div>
    </>
  )
}

export default TextFilter
