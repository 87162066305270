import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '~/components/atoms/Button'

import AlertIcon from '~/assets/icons/AlertIcon'
import CheckIcon from '~/assets/icons/CheckIcon'
import { Loading } from '~/assets/icons/Loading'

export enum ProcessingStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  DOWNLOADING = 'DOWNLOADING',
  WAITING_DOWNLOAD_RESULT = 'WAITING_DOWNLOAD_RESULT',
  CANCELED = 'CANCELED',
  DOWNLOADING_RESULT = 'DOWNLOADING_RESULT',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

interface Props {
  status: ProcessingStatus
  onRequestOcr: () => void
}

const OcrProcessingStatusComponent = ({ status, onRequestOcr }: Props) => {
  const { t } = useTranslation()

  if (status === ProcessingStatus.NEW) {
    return <Button type="filled" className="!h-[30px] !px-[10px]" onClick={onRequestOcr} text={t('mapping.ocr_execute')} />
  } else if (status === ProcessingStatus.COMPLETED) {
    return (
      <div className="flex items-center h-[30px]">
        <CheckIcon />
        <span className="text-sm font-medium text-blue-100 ml-1">{t('mapping.ready')}</span>
      </div>
    )
  } else if (status === ProcessingStatus.FAILED) {
    return (
      <Button className="flex items-center !px-4 h-[30px]" type="outlined" onClick={onRequestOcr} text={t('mapping.ocr_rerun')} prefixIcon={<AlertIcon />} />
    )
  } else {
    return (
      <div className="flex items-center h-[30px]">
        <div className="flex items-center justify-center w-4 h-4 animate-spin">
          <Loading width="100%" height="100%" />
        </div>
        <span className="text-sm font-medium text-blue-100 ml-1">{t('mapping.ocr_running')}</span>
      </div>
    )
  }
}

export const OcrProcessingStatus = React.memo(OcrProcessingStatusComponent)
