import React from 'react'

interface CheckIconProps {
  isActive?: boolean
  width?: string
  height?: string
  color?: string
}

const CheckIcon = ({ isActive = false, width = '16', height = '16', color = '#3ca4ff' }: CheckIconProps) => {
  return (
    <svg id="icon-action-check_circle_24px" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
      <rect id="Boundary" width={width} height={height} fill="none" />
      <path
        id="_Color"
        data-name=" ↳Color"
        d="M3.818,7.383.955,4.458,0,5.433l3.818,3.9L12,.975,11.045,0Z"
        transform="translate(2 3.333)"
        fill={isActive ? '#FFFFFF' : color}
      />
    </svg>
  )
}

export default CheckIcon
