import React from 'react'

const DatePickerIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z"
        stroke="#171717"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V7C8.75 7.41421 8.41421 7.75 8 7.75C7.58579 7.75 7.25 7.41421 7.25 7V3C7.25 2.58579 7.58579 2.25 8 2.25ZM16 2.25C16.4142 2.25 16.75 2.58579 16.75 3V7C16.75 7.41421 16.4142 7.75 16 7.75C15.5858 7.75 15.25 7.41421 15.25 7V3C15.25 2.58579 15.5858 2.25 16 2.25ZM4 10.25C3.58579 10.25 3.25 10.5858 3.25 11C3.25 11.4142 3.58579 11.75 4 11.75H20C20.4142 11.75 20.75 11.4142 20.75 11C20.75 10.5858 20.4142 10.25 20 10.25H4ZM10.25 15C10.25 14.5858 10.5858 14.25 11 14.25H12C12.4142 14.25 12.75 14.5858 12.75 15V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V15.75H11C10.5858 15.75 10.25 15.4142 10.25 15Z"
        fill="#171717"
      />
    </svg>
  )
}

export default DatePickerIcon
