import React from 'react'

const HomeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
      <path
        fill="#000"
        d="M16.3 20.555h-2c-.36 0-.65 0-.97-.15-.29-.14-.52-.36-.66-.64-.17-.32-.17-.62-.17-.97v-6.34-.53c-.12-.01-.36-.01-.59-.01h-2.8c-.23 0-.47 0-.59.01v6.87c0 .35 0 .65-.17.98-.15.28-.38.5-.66.64-.32.16-.6.16-.97.16h-2c-1.23 0-1.91 0-2.55-.31a2.95 2.95 0 01-1.32-1.27c-.33-.63-.33-1.29-.33-2.49v-6.95c0-.61 0-.97.11-1.36.1-.34.26-.67.48-.95.25-.32.54-.54 1.03-.91l6.78-5.1c.41-.31.68-.51 1.07-.61.34-.09.69-.09 1.03 0 .38.1.65.3 1.06.61l6.78 5.07c.5.37.79.59 1.04.91.22.29.39.61.49.96.11.39.11.76.11 1.36v6.95c0 1.2 0 1.86-.33 2.49-.29.55-.75.99-1.32 1.27-.64.31-1.32.31-2.55.31zm-1.8-2h1.8c.87 0 1.45 0 1.67-.11.19-.09.34-.23.43-.41.1-.19.1-.76.1-1.56v-6.95c0-.43 0-.69-.03-.8a.847.847 0 00-.15-.3c-.08-.1-.29-.26-.64-.52l-6.8-5.07c-.17-.13-.35-.26-.41-.29 0 .03-.19.16-.36.29l-6.78 5.07c-.36.27-.57.43-.65.53-.07.09-.12.19-.15.29-.03.12-.03.38-.03.8v6.95c0 .79 0 1.37.1 1.56.09.18.24.32.43.41.22.11.8.11 1.67.11h1.8v-6.11c0-.64 0-1.06.23-1.48.2-.37.5-.66.88-.85.42-.21.83-.21 1.49-.21h2.8c.67 0 1.07 0 1.49.21.38.19.68.48.88.85.23.43.23.85.23 1.48v6.11z"
      ></path>
    </svg>
  )
}

export default HomeIcon
