import { notification } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { cloneDeep, findIndex, get, groupBy, isNaN, last, pick, uniq } from 'lodash'
import { ReactNode } from 'react'

import { COMPARATIVE, DEFAULT_GROUP_CODE, NOTIFICATION_KEY, RULE_CATEGORY, SELECT_COST_TYPE_TITLE } from '~/consts/common'
import { HttpStatusCode } from '~/consts/httpStatusCode'

export type NotificationType = 'success' | 'info' | 'warning' | 'error'

export const handleError = (err: any) => {
  const { status, data } = err.response || {}

  if (status === HttpStatusCode.FORBIDDEN) {
    return t('message.common.http_error_403')
  }
  if (status === HttpStatusCode.NOT_FOUND && Object.keys(data?.data).length !== 0) {
    return t('message.common.http_error_404')
  }
  if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
    return data?.data?.map((item: any) => t('message.common.http_error_422', { attribute: `${item.code}` }))
  }
  if (status === HttpStatusCode.BAD_GATEWAY) {
    return t('message.common.http_error_502')
  }
  if (status === HttpStatusCode.GATEWAY_TIMEOUT) {
    return t('message.common.http_error_504')
  }
  if (status === HttpStatusCode.METHOD_NOT_ALLOWED) {
    return data?.message
  }
  if (data?.data && typeof data?.data !== 'string' && Object.keys(data?.data).length > 0) {
    return data?.data
      .map(
        (item: any) => (typeof item.code !== 'string' ? t(`message.status_error_400.code_${item.code}`) : item.code)
        // : t('message.common.http_error_400', { attribute: `${item.code}` })
      )
      .join('\n')
  }
  if (err.code === 'AuthenticationError') {
    return '認証エラー'
  }
  if (status === HttpStatusCode.INTERNAL_SERVER_ERROR && get(data, 'data.0.code')) {
    return get(data, 'data.0.code', '')
  } else {
    return data?.message || 'エラー'
  }
}
export const formatNumber = (number: any, locales = 'en-US') => {
  return number && !isNaN(Number(number)) ? Intl.NumberFormat(locales).format(number) : number
}
export const formatNumberFractionDigits = (number: any, locales = 'en-US') => {
  return number && !isNaN(Number(number))
    ? Intl.NumberFormat(locales, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number)
    : number
}

export const formatDecimal = (number: number) => {
  return (Math.round(number * 100) / 100).toFixed(1)
}

export const showTotalPaginationTable = (from: number, to: number, total: number): string => `${from}-${to}件 / ${total}件`

export const generateUniqueKey = (prefix = '', index: number) => `${prefix}-${index}`

export const generateUniqueNumberKey = () => {
  const timestamp = Date.now()
  const random = Math.floor(Math.random() * 100000)
  return parseInt(`${timestamp}${random}`)
}

export const checkValidFormValues = (value: string | null, array: Array<string | null>) => array.includes(value)

export const pushNotification = (message: string | ReactNode, type: NotificationType, duration = 5, extrasClassName = '', key = NOTIFICATION_KEY) => {
  const checkTypeCss = type === 'success' ? 'bg-[#b7eb8f]' : 'bg-[#ffccc7] pre-line'

  const config = {
    message,
    placement: 'bottomRight',
    className: `notification-type notification-type-${type} ${checkTypeCss} ${extrasClassName}`,
    duration,
    key,
  }

  return notification[type](config)
}

export const filterObject = (obj) => {
  const result = {}
  for (const key in obj) {
    if (obj[key]) {
      result[key] = obj[key]
    }
  }

  return result
}

export const ruleCategory = (category: number) => {
  switch (category) {
    case 10:
      return RULE_CATEGORY.CATEGORY_10.name
    case 20:
      return RULE_CATEGORY.CATEGORY_20.name
    case 30:
      return RULE_CATEGORY.CATEGORY_30.name
    case 40:
      return RULE_CATEGORY.CATEGORY_40.name
    case 50:
      return RULE_CATEGORY.CATEGORY_50.name
    default:
      return ''
  }
}

export const ruleComparative = (text) => {
  switch (text) {
    case 'lt':
      return COMPARATIVE.COMPARATIVE_LT
    case 'gte':
      return COMPARATIVE.COMPARATIVE_GTE
    case 'eq':
      return COMPARATIVE.COMPARATIVE_EQ
    case 'ne': {
      return COMPARATIVE.COMPARATIVE_NE
    }
    default:
      return null
  }
}

export const ruleECostType = (category: number) => {
  switch (category) {
    case 10:
      return SELECT_COST_TYPE_TITLE.CATEGORY_10
    case 20:
      return SELECT_COST_TYPE_TITLE.CATEGORY_20
    case 30:
      return SELECT_COST_TYPE_TITLE.CATEGORY_30

    default:
      ''
  }
}
export const sortArrayByField = (arr: any[], field: string, isAsc = true) => {
  return arr.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1
    }
    if (a[field] > b[field]) {
      return isAsc ? 1 : -1
    }
    return 0
  })
}
const allChildKeys = (children) => {
  if (!children) return []
  const childKeys = children.flatMap((child) => {
    const childObj = { ...child }
    childObj.children = []
    return [childObj.key, ...allChildKeys(child.children)]
  })
  return childKeys
}

export const getKeyInArr = (array: any[], property = 'key') => {
  return array
    .map((obj) => {
      const keys = [obj[property]]
      if (obj.children) {
        const childKeys = obj.children.flatMap((child) => {
          const childObj = { ...child }
          childObj.children = []
          return [childObj[property], ...allChildKeys(child.children)]
        })
        keys.push(...childKeys)
      }
      return keys
    })
    .flat() as string[]
}

export const mergeAndSort = (obj1: any, obj2: any) => {
  const mergedObj = {}
  for (const key in obj1) {
    mergedObj[key] = obj1[key]
  }
  for (const key in obj2) {
    if (typeof obj2[key] === 'object') {
      if (!mergedObj[key]) {
        mergedObj[key] = {}
      }
      mergedObj[key] = mergeAndSort(mergedObj[key], obj2[key])
    } else {
      mergedObj[key] = obj2[key]
    }
  }
  return mergedObj
}

export const markLeafNodes = (treeData) => {
  treeData.forEach((node) => {
    if (node.children) {
      markLeafNodes(node.children)
      node.end = false
    } else {
      node.end = true
    }
  })
  return treeData
}
const selectedRecursion = (parentArr, term, i = 1) => {
  if (!parentArr) return
  if (parentArr.children) {
    const termArr = term.split('-')
    const selected = parentArr.children.find((item) => {
      return item.index === termArr.slice(0, i + 1).join('-') && item.index.length !== term
    })
    if (!selected) {
      parentArr.children = []
    } else {
      parentArr.children = [selected]
    }
    selectedRecursion(selected, term, i + 1)
  }
}

export const getSelectedHandler = (originalData: any[], itemTerm: string) => {
  const levels = itemTerm.split('-')
  const clonedArray = cloneDeep(originalData[levels[0]])
  selectedRecursion(clonedArray, itemTerm)
  return [clonedArray]
}

export const deepMergeTree = (arr1, arr2) => {
  const merged: any = []

  arr1.forEach((item1) => {
    const item2 = arr2.find((item2) => item2.key === item1.key)
    if (item2) {
      const mergedItem = { ...item1, ...item2 }
      mergedItem.children = deepMergeTree(item1.children, item2.children)
      merged.push(mergedItem)
    } else {
      merged.push(item1)
    }
  })
  arr2.forEach((item2) => {
    const item1 = arr1.find((item1) => item1.key === item2.key)
    if (!item1) {
      merged.push(item2)
    }
  })

  return sortTreeData(merged)
}

export const removeKey = (treeData: any[], key: string) => {
  return sortTreeData(
    treeData
      .map((item) => {
        if (item.key === key) {
          return null
        }
        if (item.children) {
          return { ...item, children: removeKey(item.children, key) }
        }
        return item
      })
      .filter((item) => item !== null)
  )
}
export const sortTreeData = (treeData: any[]) => {
  const treeClone = [...treeData]
  if (treeClone.length > 1) {
    treeClone.sort((a, b) => {
      return a.key - b.key
    })
  }
  treeClone.forEach((item) => {
    if (item.children) {
      sortTreeData(item.children)
    }
  })

  return treeClone
}
export const addChildIndex = (treeData) => {
  const traverse = (node, parentIndex) => {
    if (node.code && node.code.length === 1) {
      node.code = `${Number(parentIndex) + 1}`
    }
    if (node.children) {
      node.children.forEach((child, index) => {
        child.prevCode = child.code
        child.code = `${node.code}-${index.length > 1 ? index.slice(0, -1) + 1 : index + 1}`
        traverse(child, child.index)
      })
    }
  }

  treeData.forEach((node, index) => {
    node.prevCode = node.code
    node.code = index.toString()
    traverse(node, index.toString())
  })
  return treeData
}

const renderArray = (array: any[]) => {
  if (Array.isArray(array) && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (i === array.length - 1) {
        array[i].end = true
      } else {
        array[i].end = false
      }
      if (Array.isArray(array[i].children) && array[i].children.length > 0) {
        renderArray(array[i].children)
      }
    }
  }
  return array
}
export const markLastEnd = (arr) => {
  const arrayClone = cloneDeep(arr)
  return renderArray(arrayClone)
}

export const addKey = (treeData: any[], property: any = 'construction_code', key = 'key', defaultValue?: any) => {
  treeData.forEach((item: any) => {
    if (!item.children) {
      item.children = []
    }
    if (item[property]) {
      item[key] = item[property]
    } else if (defaultValue) {
      item[key] = defaultValue
    }
    if (item.children && item.children.length > 0) {
      addKey(item.children, property, key, defaultValue)
    }
  })
  return treeData
}

export const revertID = (treeData: any) => {
  treeData.forEach((item, index) => {
    item.a0_no_rate = item.a0_NO_rate
    item.e_no_rate = item.e_NO_rate
    if (!item.id) {
      item.id = index
    }
    if (!item.children) {
      item.children = []
    }
    if (item.children && item.children.length > 0) {
      item.id = `${item.id}-parent`
      revertID(item.children)
    }
  })
  return treeData
}

export const sortedTreeData = (treeData: any, key = 'object_registrations_number') => {
  treeData.forEach((item) => {
    if (item.children && item.children.length > 0) {
      item.children.sort((a, b) => {
        const aVal = typeof a[key] === 'string' ? parseInt(a[key].split('-')[1]) || a[key] : a[key]
        const bVal = typeof b[key] === 'string' ? parseInt(b[key].split('-')[1]) || b[key] : b[key]
        return aVal === bVal ? 0 : aVal < bVal ? -1 : 1
      })
      sortedTreeData(item.children)
    }
  })
  return treeData
}

export const addEstimateId = (treeData: any, estimate_id: string) => {
  treeData.forEach((item) => {
    item.estimate_id = estimate_id
    item.estimate_construction_id = null
    item.created_at = dayjs().format()
    item.updated_at = dayjs().format()
    item.item_number = item.code
    delete item.parent_code
    // delete item.construction_name
    delete item.construction_code
    delete item.indirect_cost_code
    delete item.category1_code
    delete item.category2_code
    delete item.category3_code
    delete item.fluid_type
    delete item.end
    delete item.index
    delete item.key
    delete item.parentEnd
    delete item.code
    // delete item.group_code
    if (item.children && item.children.length > 0) {
      addEstimateId(item.children, estimate_id)
    }
  })
  return treeData
}
export const reFormatDataSubmit = (treeData: any, caseFormat: string | null = null) => {
  const newTreeSubmit = treeData.map((item: any) => {
    const newItem = pick(item, [
      'id',
      'quantity',
      'e_cost',
      'offer_amount',
      'a0_gross_profit_rate',
      'e_gross_profit_rate',
      'list_price',
      'offer_unit_cost',
      // 'name',
      'order_accuracy',
      'a0_cost',
    ])
    const itemPut = {
      ...newItem,
      a0_mul_rate: item.used_a0_mul_rate,
      a0_NO_rate: item.a0_no_rate,
      e_NO_rate: item.e_no_rate,
    }
    const updatedItem: any = caseFormat !== 'finish' && item.edit_key ? Object.assign(itemPut, { edit_key: item.edit_key }) : itemPut
    if (item.children && item.children.length > 0) {
      updatedItem.children = reFormatDataSubmit(item.children, caseFormat)
    }
    return updatedItem
  })
  return newTreeSubmit
}
export const addKeyGetIdEstimate = (treeData: any) => {
  treeData.forEach((item) => {
    if (item.construction.construction_code) {
      item.key = item.construction.construction_code
    }
    if (item.children && item.children.length > 0) {
      addKey(item.children)
    }
  })
  return treeData
}

export const addKeyValueSelectTree = (treeData: any) => {
  treeData.forEach((item) => {
    if (!item.children) {
      item.children = []
    }
    if (item.construction_id) {
      item.key = `${item.construction_id}_${item.item_number}_${item.estimate_construction_id}`
      item.value = `${item.construction_id}_${item.item_number}_${item.estimate_construction_id}`
      item.title = item.construction_name || ''
    }
    if (item.children && item.children.length > 0) {
      addKeyValueSelectTree(item.children)
    }
  })
  return treeData
}

export const checkChildrenEnd = (treeData) => {
  const cloneData = cloneDeep(treeData)
  const traverse = (node, parentIndex) => {
    if (node.children) {
      node.children.forEach((child, index) => {
        const content = `${index}+${node?.end ? 'end' : 'notEnd'}`
        child.parentEnd = `${parentIndex}-${content}`
        traverse(child, child.parentEnd)
      })
    }
  }
  cloneData.forEach((node, index) => {
    node.parentEnd = `${index}+${node?.end ? 'end' : 'notEnd'}`
    traverse(node, node.parentEnd)
  })
  return cloneData
}

export const addIndex = (treeData) => {
  const traverse = (node, parentIndex: number) => {
    if (node.children) {
      node.children.forEach((child, index) => {
        child.index = `${parentIndex}-${index}`
        traverse(child, child.index)
      })
    }
  }

  treeData.forEach((node, index) => {
    node.index = index.toString()
    traverse(node, index.toString())
  })
  return treeData
}

export const groupedObj = (objectValue) => {
  return Object.keys(objectValue).reduce((result, key) => {
    const [property, id] = key.split('&')
    if (!result[id]) {
      result[id] = {}
    }
    result[id][property] = objectValue[key]
    result[id].id = id

    return result
  }, {})
}

export const removeStructData = (treeData: any) => {
  const newData: any = []
  treeData.forEach((item: any) => {
    newData.push({
      ...item,
      children: [],
    })

    item.children.forEach((child) => {
      newData.push({
        ...child,
        children: [],
      })
    })
  })
  return newData
}

export const convertObject = (dataObject) => {
  const toArray = Object.keys(dataObject).map((key) => {
    if (Array.isArray(dataObject[key])) {
      return {
        title: key,
        contract_amount: dataObject[key][0],
        a0_cost: dataObject[key][1],
        a0_gross_profit_rate: dataObject[key][2],
        e_cost: dataObject[key][3],
        e_gross_profit_rate: dataObject[key][4],
        cost_per_space: dataObject[key][5],
        cost_per_capacity: dataObject[key][6],
      }
    } else {
      return {
        title: key,
        ...dataObject[key],
      }
    }
  })
  return toArray
}

export const keepLatestItemsByKey = (array, key = 'id') => {
  const groups = groupBy(array, key) || {}
  const uniqueItems = Object.values(groups).map((group) => last(group))
  return uniqueItems
}

export const pushOrUpdateItemByKey = (array, item, key = 'id') => {
  const index = findIndex(array, { [key]: item[key] })
  if (index !== -1) {
    array[index] = item
  } else {
    array.push(item)
  }
}

const findAndMergeChildren = (arr1, arr2) => {
  const mergedChildren: any = []
  for (const item of arr1) {
    const existingItem = arr2.find((el) => el.id === item.id)
    if (existingItem) {
      const mergedItem = mergeObjects(existingItem, item)
      pushOrUpdateItemByKey(mergedChildren, mergedItem)
    } else {
      pushOrUpdateItemByKey(mergedChildren, item)
    }
  }

  return uniq(mergedChildren)
}
export const mergeObjects = (obj1, obj2) => {
  const mergedObject = { ...obj1 }

  for (const prop in obj2) {
    if (prop === 'children') {
      mergedObject.children = findAndMergeChildren(obj1.children, obj2.children)
    } else if (prop === 'edit_key') {
      mergedObject.edit_key = uniq(obj1.edit_key && obj1.edit_key.length > 0 ? [...obj1.edit_key, ...obj2.edit_key] : [...obj2.edit_key])
    } else if (typeof obj2[prop] === 'object' && obj2[prop] !== null) {
      mergedObject[prop] = mergeObjects(mergedObject[prop], obj1[prop])
    } else {
      mergedObject[prop] = obj2[prop]
    }
  }
  return mergedObject
}

export const findParentsFromChildIds = (dataArray, childIds) => {
  const getParent = (currentItem) => {
    if (childIds.includes(currentItem.id)) {
      return currentItem
    }
    if (currentItem.children) {
      for (const child of currentItem.children) {
        const parent = getParent(child)
        if (parent) {
          return parent
        }
      }
    }
    return null
  }

  let parent = null
  for (const item of dataArray) {
    parent = getParent(item)
    if (parent) {
      break
    }
  }
  return parent
}

export const generateItemUniqueKeys = (item: any) => {
  if (!item?.children || item?.children?.length === 0) {
    item.key = generateUniqueNumberKey()
  } else {
    item.key = generateUniqueNumberKey()
    item?.children?.forEach((child: any) => generateItemUniqueKeys(child))
  }

  return item
}

export const groupItemPropertyByCode = (data: any[], property: any = 'key'): Record<string, number[]> => {
  const groups: Record<number, number[]> = {}
  for (const item of data) {
    const groupCode = item?.group_code || DEFAULT_GROUP_CODE
    if (!(groupCode in groups)) {
      groups[groupCode] = []
    }
    groups[groupCode]?.push(item[property])
    if (item?.children && item?.children?.length) {
      const childGroups = groupItemPropertyByCode(item?.children)
      for (const childGroupCode in childGroups) {
        if (!(childGroupCode in groups)) {
          groups[childGroupCode] = []
        }
        groups[childGroupCode] = groups[childGroupCode].concat(childGroups[childGroupCode])
      }
    }
  }
  return groups
}

export const mapItemPropertyByCode = (data: any[], property: any = 'key', result: any = {}) => {
  for (const item of data) {
    if (item[property]) {
      result[item?.code] = item[property]
    }
    if (item?.children && item?.children?.length) {
      mapItemPropertyByCode(item?.children, property, result)
    }
  }
  return result
}

export const groupCustomValueByCode = (data: any[], value: any): Record<string, any> => {
  const groups: Record<number, any> = {}
  for (const item of data) {
    const groupCode = item?.group_code || DEFAULT_GROUP_CODE
    groups[groupCode] = value
  }
  return groups
}

export const groupItemsByCode = (data: any[]): any[][] => {
  const groupedData: { [key: number]: any[] } = {}

  for (const item of data) {
    if (!(item?.group_code in groupedData)) {
      groupedData[item?.group_code] = []
    }
    groupedData[item?.group_code].push(item)
  }

  return Object.values(groupedData)
}

export const removeParentItemByGroupCode = (data: any[], groupCodeToRemove: string): any[] => {
  const updatedData: any[] = []

  // Recursively remove the parent item by group_code
  const removeParentItem = (item: any): any | null => {
    if (item?.group_code === groupCodeToRemove) {
      return null
    } else {
      const updatedChildren = item?.children?.map((child: any) => removeParentItem(child)).filter((child: any) => child !== null)
      const updatedGroupCode = parseInt(item?.group_code) - (item?.group_code > groupCodeToRemove ? 1 : 0)
      return {
        ...item,
        children: updatedChildren,
        group_code: updatedGroupCode.toString(),
      }
    }
  }

  // Remove the parent item and update the group_code of the remaining items
  data.forEach((item: any) => {
    const updatedConstruction = removeParentItem(item)
    if (updatedConstruction !== null) {
      updatedData.push(updatedConstruction)
    }
  })

  return updatedData
}

export const swapParentItemsByGroupCode = (data: any[], groupCode1: string, groupCode2: string): any[] => {
  const traverse = (item: any): any => {
    const updatedChildren = item?.children?.map(traverse)
    const updatedGroupCode = item?.group_code === groupCode1 ? groupCode2 : item?.group_code === groupCode2 ? groupCode1 : item?.group_code

    return {
      ...item,
      children: updatedChildren,
      group_code: updatedGroupCode,
    }
  }

  return data.map(traverse)
}

export const collectPropertyTreeData = (data: any[], prop: string): any[] => {
  const values: any[] = []
  const stack = [...data]
  while (stack.length > 0) {
    const item = stack.pop()
    values.push(item[prop])
    if (item.children) {
      stack.push(...item.children)
    }
  }
  return values.filter((value) => value !== undefined)
}

export const flattenTreeData = (data: any[]): any[] => {
  return data.flatMap((item) => {
    if (item.children) {
      return [item, ...flattenTreeData(item.children)]
    }
    return item
  })
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color: any = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const checkItem = (childrenCheck, mergerRecord) => {
  const childrenSubmit = childrenCheck.map((child) => {
    if (child.children.length > 0) {
      return {
        id: child.id.split('-parent')[0] || child.id,
        children: checkItem(child.children, mergerRecord),
      }
    }
    if (child.id === mergerRecord.id) {
      return {
        ...mergerRecord,
      }
    }
    return { ...child, id: child.id }
  })
  return childrenSubmit.flat()
}

export const revertDataSubmit = (keyTab: string, currentItem: any, matchingItem: any) => {
  if (matchingItem.id === currentItem.id) {
    return matchingItem
  } else {
    return {
      ...currentItem,
      id: keyTab === 'machine' ? currentItem.id.split('-parent')[0] || currentItem.id : null,
      children: checkItem(currentItem.children, matchingItem),
    }
  }
}

export const findParent = (dataArray, childId) => {
  const parent = dataArray.find((item) => (item.children ? item.children.some((child) => childId.includes(child.id)) : false))
  return parent || null
}

export const findParentAll = (dataArray, childId) => {
  const parent = dataArray.find((item) =>
    item.children ? item.children.some((item) => (item.id.includes('-parent') ? item.id.split('-parent')[0] === childId : false)) : false
  )
  return parent || null
}

export const moveOrAddElementToEnd = (array, element) => {
  const index = array.indexOf(element)
  if (index !== -1) {
    array.splice(index, 1)
  }
  array.push(element)
}

export const updateChild = (dataRevertSubmit: any, checkParentRemain) => {
  const mer = mergeObjects(dataRevertSubmit, checkParentRemain)
  return mer
}

export const checkMerge = (oldInList, dataEdit) => {
  const convert = oldInList.children.map((children) => {
    if (children.id.includes(dataEdit.children[0].id)) {
      return {
        ...dataEdit.children[0],
      }
    } else {
      return {
        id: children.id,
      }
    }
  })
  const dataRes = {
    id: null,
    children: convert,
  }
  return dataRes
}
