import { useRoutes } from 'react-router-dom'

import { useSocket } from '~/hooks/useSocket'
import estimateRouter from '~/routes/estimate'
import PrivateBasicRouter from '~/routes/privateBasic'

import EmptyLayoutRouter from './privateEmpty'

export default function Routes() {
  useSocket()
  return useRoutes([PrivateBasicRouter, estimateRouter, EmptyLayoutRouter])
}
