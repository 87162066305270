import React from 'react'

import PageLoading from '~/components/molecules/PageLoading'

interface Props {
  children?: React.ReactNode
}
export const Suspense = ({ children }: Props) => {
  return <React.Suspense fallback={<PageLoading />}>{children}</React.Suspense>
}
