import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import PrivateRoute from '~/components/organisms/privateRoute'

import { ROUTER_PATH } from '~/consts/router'
import EstimateLayout from '~/layout/EstimateLayout'

import { Suspense } from './suspense'

const ProjectDetail = lazy(() => import('~/features/project/detail'))
const Construction = lazy(() => import('~/features/construction'))
const Machines = lazy(() => import('~/features/machines/Machines'))
const EstimateDocument = lazy(() => import('~/features/estimateDocument'))
const History = lazy(() => import('~/features/histories'))
const DeviceMapping = lazy(() => import('~/features/deviceMapping'))

const estimateRouter: RouteObject = {
  path: ROUTER_PATH.PROJECT.DETAIL,
  element: (
    <PrivateRoute>
      <EstimateLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: '',
      element: (
        <Suspense>
          <ProjectDetail />
        </Suspense>
      ),
    },
    {
      path: 'constructions',
      element: (
        <Suspense>
          <Construction />
        </Suspense>
      ),
    },
    {
      path: 'machines',
      element: (
        <Suspense>
          <Machines />
        </Suspense>
      ),
    },
    {
      path: 'estimate-document',
      element: (
        <Suspense>
          <EstimateDocument />
        </Suspense>
      ),
    },
    {
      path: 'histories',
      element: (
        <Suspense>
          <History />
        </Suspense>
      ),
    },
    {
      path: 'mapping',
      element: (
        <Suspense>
          <DeviceMapping />
        </Suspense>
      ),
    },
  ],
}

export default estimateRouter
