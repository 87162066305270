import * as i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { DEFAULT_LANGUAGE, LANGUAGE_STORE_KEY } from '~/consts/common'
import en from '~/locales/en'
import ja from '~/locales/ja'
import { getLocalStorage } from '~/utils/storage'

const resources = {
  en,
  ja,
}

const lng = getLocalStorage(LANGUAGE_STORE_KEY, DEFAULT_LANGUAGE)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
  })

export default i18n
