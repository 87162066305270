import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

import { store } from '~/redux/store'
import Routes from '~/routes'

import '~/assets/styles/app.scss'
import { doRefreshToken } from './api/authenApi'

const App: React.FC = () => {
  useEffect(() => {
    // Refresh token immediately on component mount
    doRefreshToken()

    const intervalId = setInterval(doRefreshToken, 600000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Provider store={store}>
      <div className="App">
        <Routes />
      </div>
    </Provider>
  )
}

export default App
