import React from 'react'
interface SettingIconProps {
  customColor?: string
  isNotImplemented?: boolean
}
const SettingIcon = ({ customColor, isNotImplemented }: SettingIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        id="settings_FILL0_wght400_GRAD0_opsz48"
        d="M11.7,24l-.5-3.15a6.7,6.7,0,0,1-1-.475,6.871,6.871,0,0,1-.925-.625L6.325,21.1,4,17l2.7-1.975a2.972,2.972,0,0,1-.062-.512q-.012-.287-.012-.512t.012-.512a2.972,2.972,0,0,1,.063-.512L4,11,6.325,6.9l2.95,1.35a6.871,6.871,0,0,1,.925-.625,5.173,5.173,0,0,1,1-.45L11.7,4h4.6l.5,3.15a8.105,8.105,0,0,1,1.013.463,4.3,4.3,0,0,1,.913.637l2.95-1.35L24,11l-2.7,1.925a3.523,3.523,0,0,1,.063.537q.012.287.013.538t-.013.525a3.481,3.481,0,0,1-.062.525L24,17l-2.325,4.1-2.95-1.35a8.293,8.293,0,0,1-.913.638,4.356,4.356,0,0,1-1.013.462L16.3,24ZM14,17.25A3.244,3.244,0,0,0,17.25,14,3.244,3.244,0,0,0,14,10.75,3.244,3.244,0,0,0,10.75,14,3.244,3.244,0,0,0,14,17.25Zm0-1.5a1.75,1.75,0,1,1,1.238-.512A1.686,1.686,0,0,1,14,15.75ZM14,14Zm-1.1,8.5h2.2l.35-2.8a5.9,5.9,0,0,0,1.563-.625A6.408,6.408,0,0,0,18.35,18.05L21,19.2l1-1.8-2.35-1.725q.1-.425.163-.838A5.583,5.583,0,0,0,19.875,14a6.954,6.954,0,0,0-.05-.837,5.173,5.173,0,0,0-.175-.838L22,10.6,21,8.8,18.35,9.95a5.573,5.573,0,0,0-1.3-1.087,4.217,4.217,0,0,0-1.6-.562L15.1,5.5H12.9l-.35,2.8a5.3,5.3,0,0,0-1.588.6A5.514,5.514,0,0,0,9.65,9.95L7,8.8,6,10.6l2.35,1.725q-.1.425-.163.838a5.642,5.642,0,0,0,0,1.675q.062.413.163.838L6,17.4l1,1.8,2.65-1.15a6.408,6.408,0,0,0,1.338,1.025,5.9,5.9,0,0,0,1.563.625Z"
        transform="translate(-4 -4)"
        opacity="0.87"
        fill={isNotImplemented ? '#A3A3A3' : customColor || '#171717'}
      />
    </svg>
  )
}

export default SettingIcon
