import React from 'react'

export interface ArrowRightProps {
  hover?: boolean
  isDisable?: boolean
  color?: string
}

const ArrowRight = ({ isDisable, hover, color }: ArrowRightProps) => {
  const iconColor = color ? color : hover ? '#38BDF8' : isDisable ? '#A3A3A3' : '#009BE0'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M8.793 19.262a1 1 0 010-1.414l5.293-5.293-5.293-5.293a1 1 0 011.414-1.414l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ArrowRight
