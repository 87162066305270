import React from 'react'

interface FilterIcon2Props {
  width?: string
  height?: string
  active?: boolean
}

const FilterIcon2 = (props: FilterIcon2Props) => {
  const width = props.width ?? '16'
  const height = props.height ?? '17'
  const active = props.active ?? false

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33301 4.32243C1.33301 3.95424 1.63148 3.65576 1.99967 3.65576H13.9997C14.3679 3.65576 14.6663 3.95424 14.6663 4.32243C14.6663 4.69062 14.3679 4.9891 13.9997 4.9891H1.99967C1.63148 4.9891 1.33301 4.69062 1.33301 4.32243ZM3.33301 8.32243C3.33301 7.95424 3.63148 7.65576 3.99967 7.65576H11.9997C12.3679 7.65576 12.6663 7.95424 12.6663 8.32243C12.6663 8.69062 12.3679 8.9891 11.9997 8.9891H3.99967C3.63148 8.9891 3.33301 8.69062 3.33301 8.32243ZM5.33301 12.3224C5.33301 11.9542 5.63148 11.6558 5.99967 11.6558H9.99967C10.3679 11.6558 10.6663 11.9542 10.6663 12.3224C10.6663 12.6906 10.3679 12.9891 9.99967 12.9891H5.99967C5.63148 12.9891 5.33301 12.6906 5.33301 12.3224Z"
          fill={active ? '#009BE0' : '#737373'}
        />
      </svg>
    </>
  )
}

export default FilterIcon2
