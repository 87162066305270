import { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { message } from '~/redux/reducers/app.slice'
import { setShowNotificationOfFile, updateUploadedFileStatus } from '~/redux/reducers/deviceMapping.slice'
import SocketService from '~/service/socket'

import { ProcessingStatus } from '../features/deviceMapping/left-sidebar/OcrProcessingStatus'

export const useSocket = () => {
  const { socketStatus } = useAppSelector(message)

  const socket = useRef<SocketService>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (socketStatus === 1) {
      console.log('=> start socket')
      socket.current = new SocketService()

      socket.current.start()

      socket.current.listen({
        event: '.file.status.changed',
        callback: async (socketData) => {
          const { id, status } = socketData
          if (status === ProcessingStatus.NEW) return
          dispatch(updateUploadedFileStatus({ id, status }))
          if (status === ProcessingStatus.COMPLETED) {
            dispatch(setShowNotificationOfFile({ id, type: 'info' }))
          } else if (status === ProcessingStatus.FAILED) {
            dispatch(setShowNotificationOfFile({ id, type: 'error' }))
          }
        },
      })
    } else if (socketStatus === 2) {
      console.log('=> stop socket')
      socket.current?.stop()
    }
  }, [socketStatus])

  useEffect(() => {
    return () => {
      socket.current?.stop()
    }
  }, [])
}
