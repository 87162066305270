import React from 'react'

interface SlideOutIconProps {
  width?: string
  height?: string
}

const SortedZA = (props: SlideOutIconProps) => {
  const width = props.width ?? '24'
  const height = props.height ?? '24'

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2884 4.29343L7.29342 8.29343L7.20942 8.38842C7.0603 8.58095 6.98655 8.82127 7.00202 9.0643C7.01749 9.30733 7.1211 9.53636 7.29342 9.70843L7.38842 9.79143C7.58095 9.94055 7.82127 10.0143 8.0643 9.99883C8.30732 9.98336 8.53636 9.87975 8.70842 9.70742L11.0004 7.41042V19.0004L11.0074 19.1174C11.0359 19.3609 11.1527 19.5854 11.3357 19.7483C11.5188 19.9113 11.7553 20.0014 12.0004 20.0014L12.1174 19.9944C12.3608 19.9657 12.5853 19.8487 12.748 19.6654C12.9108 19.4822 13.0006 19.2455 13.0004 19.0004V7.41742L15.2934 9.70742L15.3884 9.79143C15.5894 9.94874 15.843 10.0232 16.0972 9.99964C16.3513 9.97605 16.5869 9.85615 16.7555 9.66452C16.9241 9.47288 17.0131 9.22402 17.0041 8.96892C16.9952 8.71382 16.889 8.47179 16.7074 8.29242L12.7014 4.29242L12.6074 4.20942C12.4149 4.0603 12.1746 3.98655 11.9316 4.00202C11.6885 4.01749 11.4605 4.1211 11.2884 4.29343Z"
        fill="#171717"
      />
    </svg>
  )
}

export default SortedZA
