import React from 'react'
import { Outlet } from 'react-router-dom'

const EmptyLayout = () => {
  return (
    <div className="main-layout relative w-screen h-screen">
      <Outlet />
    </div>
  )
}

export default EmptyLayout
