// Cookie
export const USER_EMAIL_COOKIE = 'current_email'
export const REFRESH_TOKEN_COOKIE = 'refresh_token'
export const ACCESS_TOKEN_COOKIE = 'access_token'
// export const ID_TOKEN_COOKIE = 'id_token'
export const ID_TOKEN_COOKIE = 'idToken'
export const EXP_TOKEN_COOKIE = 'exp_token'

// Local storage
export const PinTypeSettingKey = 'pin_type_setting'
export const EXP_IN = 'expired_at'
export const IS_LOGGED_IN = 'is_logged_in'
export const TOKEN_TTL = 86000 * 1000
export const INTERVAL_TIME_REFRESH = 5 * 60 * 1000
export const SELECTED_OFFICE = 'selected_office'
export const USER_ID = 'user_id'
