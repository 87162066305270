import { Checkbox, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'

import SortedAZ from '~/assets/icons/SortedAZ'
import SortedZA from '~/assets/icons/SortedZA'

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

interface Props {
  value?: Sort
  onChange?: (value: Sort | null) => void
}

const initialValue = Sort.DESC

const SortFilter = ({ value: inputValue, onChange }: Props) => {
  const [value, setValue] = useState<Sort>(inputValue)
  const [active, setActive] = useState(Boolean(inputValue))

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setActive(checked)
    if (onChange) onChange(checked ? value : null)
  }

  // handle reset value
  useEffect(() => {
    if (inputValue) return
    setValue(initialValue)
    setActive(false)
  }, [inputValue])

  return (
    <>
      <Checkbox className="py-2" checked={active} onChange={handleCheck}>
        並べ替え
      </Checkbox>
      <Radio.Group
        className="block pl-6 pb-3"
        disabled={!active}
        onChange={(e) => {
          const sortValue = e.target.value as Sort
          setValue(sortValue)
          if (onChange) onChange(sortValue)
        }}
        defaultValue={Sort.DESC}
      >
        <Space direction="horizontal">
          <Radio className="flex items-center" value={Sort.DESC}>
            <div className="flex">
              <SortedZA />
              降順
            </div>
          </Radio>
          <Radio className="flex items-center" value={Sort.ASC}>
            <div className="flex">
              <SortedAZ />
              昇順
            </div>
          </Radio>
        </Space>
      </Radio.Group>
    </>
  )
}

export default SortFilter
