import React from 'react'

interface Props {
  width?: string
  height?: string
  color?: string
}

const AlertIcon = ({ width = '16', height = '16', color = '#DC2626' }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99935 14.0007C7.26602 14.0007 6.66602 13.4007 6.66602 12.6673C6.66602 11.934 7.25268 11.334 7.99935 11.334C8.73268 11.334 9.33268 11.934 9.33268 12.6673C9.33268 13.4007 8.73268 14.0007 7.99935 14.0007ZM7.99935 9.33398C7.26602 9.33398 6.66602 8.73398 6.66602 8.00065V2.66732C6.66602 1.93398 7.26602 1.33398 7.99935 1.33398C8.73268 1.33398 9.33268 1.93398 9.33268 2.66732V8.00065C9.33268 8.73398 8.73268 9.33398 7.99935 9.33398Z"
        fill={color}
      />
    </svg>
  )
}

export default AlertIcon
