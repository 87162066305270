import { Table } from 'antd'
import React from 'react'

import Button from '~/components/atoms/Button'

import FilterIcon from '~/assets/icons/FilterIcon2'
import { showTotalPaginationTable } from '~/utils/helper'

interface TableProjectProps {
  id?: string
  columnsDefine: any[]
  data: any[]
  total: number
  from: number
  to: number
  per_page: number
  current_page: number
  loading: boolean
  disabledReset?: boolean
  handleAction?: any
  handleReset?: any
}

const TableProject = ({
  id,
  current_page,
  columnsDefine,
  data,
  total,
  to,
  from,
  per_page,
  loading,
  disabledReset,
  handleAction,
  handleReset,
  ...rest
}: TableProjectProps) => {
  return (
    <>
      <Button
        type="outlined"
        className="h-7 flex items-center z-10"
        text="フィルター解除"
        prefixIcon={<FilterIcon />}
        disabled={disabledReset}
        onClick={handleReset}
      />
      <Table
        id={id}
        className={data.length ? 'mt-[-1.875rem]' : 'mt-6'}
        scroll={{ x: 'max-content', y: 'auto' }}
        tableLayout="fixed"
        columns={columnsDefine}
        dataSource={data}
        rowKey={(record: any) => record.id}
        loading={loading}
        onChange={handleAction}
        pagination={{
          position: ['topRight'],
          total: total ? total + Math.ceil(total / per_page) : 0,
          current: current_page || 1,
          showTotal: () => <span className="flex flex-wrap items-center">{showTotalPaginationTable(from || 1, to || 1, total || 0)}</span>,
          pageSize: per_page && per_page + 1,
        }}
        {...rest}
      />
    </>
  )
}

export default React.memo(TableProject)
